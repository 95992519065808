import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { ReceiverInfoType } from 'src/api/models';
import { useGetNotification } from 'src/api/notificationApi';
import { useDeleteReceiverInfo, useGetMyReceiverInfo } from 'src/api/receiverInfoApi';
import useProfile from 'src/hooks/useProfile';
import { useSetNotificationBadge } from 'src/state/application/hooks';
import './ReceiverInfo.css';
import { useNavigate } from 'react-router-dom';

const ReceiverInfo: React.FC = () => {
  //Value
  const profile = useProfile();
  const navigate = useNavigate();

  //State
  const [receiverInfoList, setReceiverInfoList] = useState<ReceiverInfoType[]>([]);

  const [reloadFlag, setReloadFlag] = useState<boolean>(false);
  //End of state

  //Function
  const getMyReceiverInfo = useGetMyReceiverInfo();
  const deleteReceiverInfo = useDeleteReceiverInfo();
  const getNotification = useGetNotification();
  const setNotificationBadge = useSetNotificationBadge();

  const onDeleteReceiverInfo = (receiverInfo: ReceiverInfoType, index: number) => {
    deleteReceiverInfo(receiverInfo.receiverInfoId).then(() => {
      receiverInfoList.splice(index, 1);
      setReceiverInfoList(receiverInfoList);
      setReloadFlag(!reloadFlag);
    });
  };

  //Component
  const receiverInfoListComponent = () => {
    return (
      <div className="row justify-content-center p-2 mt-2 mr-2 ml-2">
        {receiverInfoList.map((value, index) => (
          <div
            className={`col-12 receiver-info_component_container ${
              index % 2 == 0 ? 'even' : ''
            }`}
          >
            <div className="re-info-wrapper">
              <div className="receiver-info_component_child">
                {value.fullname} | {value.phoneNumber}
              </div>
              <div className="receiver-info_component_child">{value.address4}</div>
              <div className="receiver-info_component_child">
                {value.address3 && value.address3 != '' ? `${value.address3}, ` : ''}
                {value.address2 && value.address2 != '' ? `${value.address2}, ` : ''}
                {value.address1 && value.address1 != '' ? value.address1 : ''}
              </div>
              <div className="receiver-info_component_child">
                {value.facebook ? `Fb: ${value.facebook} | ` : ''}
                {value.zalo ? `Zalo: ${value.zalo}` : ''}
              </div>
            </div>
            <div className="choose-re-info_edit-btn">
              <button
                className="dashboard_button_child re-info_cpn_btn"
                onClick={() => {
                  navigate(`/receiver-info/detail?tid=${value.receiverInfoId}`);
                }}
              >
                Sửa
              </button>
            </div>
            <div className="choose-re-info_delete-btn">
              <button
                className="dashboard_button_child del_btn"
                onClick={() => onDeleteReceiverInfo(value, index)}
              >
                Xóa
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const addComponent = () => {
    return (
      <div className={`row justify-content-center p-2 m-2`}>
        <div className="accordion col-12 m-0 p-0" id="myaccordion">
          <div className="m-0 address-wrapper">
            <button
              className="dashboard_button_child address"
              data-toggle="collapse"
              data-target="#noidungcard1"
              aria-expanded="true"
              data-parent="#myaccordion"
              onClick={() => navigate(`/receiver-info/detail`)}
            >
              Thêm địa chỉ
            </button>
          </div>
        </div>
      </div>
    );
  };
  //End of component

  //useEffect
  useEffect(() => {
    getMyReceiverInfo()
      .then((data) => {
        setReceiverInfoList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyReceiverInfo, reloadFlag]);

  useEffect(() => {
    if (profile) {
      getNotification(1, 1)
        .then((data) => {
          setNotificationBadge(data.badge);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getNotification, profile, setNotificationBadge]);

  //Main
  return profile ? (
    <div className="container-fluid body dashboard-container tracking-container col-lg-11 col-12 p-0">
      <div className="tracking-title">
        {receiverInfoList.length > 0 ? 'Địa chỉ nhận hàng' : 'Bạn chưa thêm địa chỉ nhận hàng'}
      </div>
      {addComponent()}
      {receiverInfoListComponent()}
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default ReceiverInfo;
