import './ProductCategory.css';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { CategoryType } from 'src/api/models';
import { useGetProductCategoryById } from 'src/api/productCategoryApi';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import Products from 'src/components/Products/Products';

const ProductCategory: React.FC = () => {

  const params = useParams<{ productCategoryId: string }>();
  const productCategoryId = Number(params.productCategoryId);
  const getProductCategoryById = useGetProductCategoryById();
  const [productCategory, setProductCategory] = useState<CategoryType>();
  
  useEffect(() => {
    getProductCategoryById(productCategoryId).then((data) => setProductCategory(data));
  }, []);

  //Main
  return (
    <PageContainer>
        <PageHeader>
          <NavLink to={'/'}>Trang chủ</NavLink>{` / `}
          {productCategory?.productCategoryName}
        </PageHeader>
      <div className="product-category-container">
      
        <Products categoryId={productCategoryId} />
      </div>
    </PageContainer>
  );
};

export default ProductCategory;
