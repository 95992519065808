import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NavItemComponent.css';

export interface INavItemComponent {
  avatar: any;
  name: string;
  url: string;
  newTab?: boolean;
}

const NavItemComponent: React.FC<INavItemComponent> = (props) => {
  //Value
  const navigate = useNavigate();

  //Main
  return (
    <div
      className="nav-item-ctn"
      title={props.name}
      onClick={() => {
        if (!props.newTab) {
          navigate(props.url);
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 0);
          window.open(props.url, '_blank');
        }
      }}
    >
      <div className="nav-item-child-img">
        {props.avatar ? <img src={props.avatar} alt="avatar" /> : null}
      </div>
      <div className="category-item-name">{props.name}</div>
    </div>
  );
};

export default NavItemComponent;
