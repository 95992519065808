import { uniqueId } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useGetShipInfo } from 'src/api/commonInfomationApi';
import { ReceiverInfoType } from 'src/api/models';
import { useGetNotification } from 'src/api/notificationApi';
import { useReadQRCode } from 'src/api/qrApi';
import {
  useGetReceiverInfoById,
  usePostReceiverInfo,
  usePutReceiverInfo,
} from 'src/api/receiverInfoApi';
import useProfile from 'src/hooks/useProfile';
import useQuery from 'src/hooks/useQuery';
import { useAddPopup, useSetNotificationBadge } from 'src/state/application/hooks';
import { normalizationZipcode } from 'src/utils/stringUtils';
import styled from 'styled-components';
import './ReceiverInfoDetail.css';

const ReceiverInfoDetail: React.FC = () => {
  //Value
  const profile = useProfile();
  const query = useQuery();
  const receiverInfoId = Number(query.get('tid'));

  //State
  const [fullname, setFullname] = useState('');
  const [fullnameError, setFullnameError] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactError, setContactError] = useState(null);

  const [zipCode, setZipCode] = useState('');

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [addressError, setAddressError] = useState(null);

  const [facebook, setFacebook] = useState('');

  const [zalo, setZalo] = useState('');

  const [focusInput, setFocusInput] = useState(null);
  //End of state

  //Function

  const navigate = useNavigate();
  const addPopup = useAddPopup();
  const postReceiverInfo = usePostReceiverInfo();
  const putReceiverInfo = usePutReceiverInfo();
  const getReceiverIndoById = useGetReceiverInfoById();
  const getShipInfo = useGetShipInfo();
  const getNotification = useGetNotification();
  const setNotificationBadge = useSetNotificationBadge();

  const onPostReceiverInfo = () => {
    const isFullName = validateFullName();
    const isContact = validateContact();
    const isAddress = validateAddress();
    if (isFullName && isContact && isAddress) {
      const receiverInfo: ReceiverInfoType = {
        fullname: fullname,
        phoneNumber: phoneNumber,
        zipCode: zipCode,
        address1: address1,
        address2: address2,
        address3: address3,
        address4: address4,
        facebook: facebook,
        zalo: zalo,
      };
      postReceiverInfo(receiverInfo)
        .then((data) => {
          setFullname(data.fullname);
          setPhoneNumber(data.phoneNumber);
          setZipCode(data.zipCode);
          setAddress1(data.address1);
          setAddress2(data.address2);
          setAddress3(data.address3);
          setAddress4(data.address4);
          setFacebook(data.facebook);
          setZalo(data.zalo);
          addPopup({
            txn: {
              success: true,
              summary: 'Thêm mới địa chỉ thành công',
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onPutReceiverInfo = () => {
    const isFullName = validateFullName();
    const isContact = validateContact();
    const isAddress = validateAddress();
    if (isFullName && isContact && isAddress && receiverInfoId) {
      const receiverInfo: ReceiverInfoType = {
        fullname: fullname,
        phoneNumber: phoneNumber,
        zipCode: zipCode,
        address1: address1,
        address2: address2,
        address3: address3,
        address4: address4,
        facebook: facebook,
        zalo: zalo,
        receiverInfoId: receiverInfoId,
      };
      putReceiverInfo(receiverInfo)
        .then((data) => {
          setFullname(data.fullname);
          setPhoneNumber(data.phoneNumber);
          setZipCode(data.zipCode);
          setAddress1(data.address1);
          setAddress2(data.address2);
          setAddress3(data.address3);
          setAddress4(data.address4);
          setFacebook(data.facebook);
          setZalo(data.zalo);
          addPopup({
            txn: {
              success: true,
              summary: 'Sửa địa chỉ thành công',
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  //parser Zipcode
  const onChangeZipCode = (zipcode: string) => {
    setZipCode(zipcode);
    const nZipcode = normalizationZipcode(zipcode);
    if (!nZipcode || nZipcode.length != 7) return;
    getShipInfo(nZipcode)
      .then((data) => {
        setAddress1(data?.pref);
        setAddress2(data?.city);
        setAddress3(data?.town);
      })
      .catch(() => {
        setAddress1(undefined);
        setAddress2(undefined);
        setAddress3(undefined);
      });
  };

  //Validate
  const validateFullName = () => {
    const bOk = fullname && fullname != '';
    setFullnameError(!bOk ? 'Chưa nhập tên người nhận' : undefined);
    return bOk;
  };

  const validateContact = () => {
    const bOk =
      (phoneNumber && phoneNumber != '') ||
      (zalo && zalo != '') ||
      (facebook && facebook != '');
    setContactError(!bOk ? 'Chưa nhập thông tin liên hệ, nhập SĐT hoặc Fb, Zalo' : undefined);
    return bOk;
  };

  const validateAddress = () => {
    const bOk =
      (address1 && address1 != '') ||
      (address2 && address2 != '') ||
      (address3 && address3 != '');
    setAddressError(!bOk ? 'Chưa nhập địa chỉ' : undefined);
    return bOk;
  };
  //End of validate

  //Component
  //1
  const fullNameInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 1 ? 'focus-input' : ''} ${
            fullname ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Tên người nhận</div>
          <input
            type="text"
            value={fullname}
            onChange={(event) => {
              setFullname(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validateFullName();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{fullnameError}</div>
      </div>
    );
  };

  //2
  const phoneNumberInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 2 ? 'focus-input' : ''} ${
            phoneNumber ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Số điện thoại</div>
          <input
            type="text"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              validateContact();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
          />
        </div>
      </div>
    );
  };

  const openZaloGuide = () => {
    window.open('/static-page/ZALO_INFO_GUIDE');
  };

  const openFacebookGuide = () => {
    window.open('/static-page/FACEBOOK_INFO_GUIDE');
  };

  //3
  const facebookInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 3 ? 'focus-input' : ''} ${
            facebook ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Link Fb</div>
          <input
            type="text"
            value={facebook}
            onChange={(event) => {
              setFacebook(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(3);
            }}
            onBlur={() => {
              validateContact();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 3}
            placeholder="Nhập thông tin Facebook (Click icon hướng dẫn bên phải)"
          />
          <div className="i-tooltip guide">
            <span className="material-icons info-guide" onClick={openFacebookGuide}>
              info
            </span>
            <span className="tooltiptext">Hướng dẫn lấy thông tin Facebook của bạn</span>
          </div>
        </div>
      </div>
    );
  };

  const readerQRCode = useReadQRCode();

  const onReaderQRCode = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.size <= 55242880) {
        const formData = new FormData();
        formData.append('name', '');
        formData.append('file', event.target.files[0]);

        readerQRCode(formData)
          .then((zaloInfo: string) => {
            setZalo(zaloInfo);
            if (zaloInfo && zaloInfo != '') setContactError(undefined);
            addPopup({
              txn: {
                success: true,
                summary: 'Phân tích QRCode thành công!',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
            setZalo(undefined);
            if (!phoneNumber || phoneNumber == '' || (!facebook && facebook == ''))
              setContactError('Chưa nhập thông tin liên hệ, nhập SĐT hoặc Fb, Zalo');
          });

        addPopup({
          txn: {
            success: true,
            summary: 'Vui lòng chờ upload QRCode!',
          },
        });
      } else {
        addPopup({
          error: { message: 'Ảnh QRCode tối đa 5MB', title: 'Đã có lỗi xảy ra!' },
        });
      }
    }
  };

  //4
  const zaloInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 4 ? 'focus-input' : ''} ${
            zalo ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Zalo</div>
          <input
            type="text"
            value={zalo}
            onChange={(event) => {
              setZalo(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(4);
            }}
            onBlur={() => {
              validateContact();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 4}
            placeholder="Nhập thông tin Zalo (Click icon hướng dẫn bên phải)"
          />
          <div className="i-tooltip qr-scan">
            <label className="custom-image-upload">
              <StyledInput
                key={uniqueId()}
                type="file"
                onChange={onReaderQRCode}
                className="filetype"
                id="qr-code-zalo"
              />
              <span className="material-icons qr-scan-icon">qr_code_scanner</span>
            </label>
            <span className="tooltiptext">Nhập mã QRCode thông tin Zalo</span>
          </div>
          <div className="i-tooltip guide">
            <span className="material-icons info-guide" onClick={openZaloGuide}>
              info
            </span>
            <span className="tooltiptext">Hướng dẫn lấy thông tin Zalo của bạn</span>
          </div>
        </div>

        <div style={{ color: 'red', paddingLeft: 2 }}>{contactError}</div>
      </div>
    );
  };

  //5
  const zipCodeInput = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 5 ? 'focus-input' : ''} ${
            zipCode ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Mã bưu điện</div>
          <input
            type="text"
            value={zipCode}
            onChange={(event) => {
              onChangeZipCode(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(5);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            placeholder={'Chỉ dùng ở Nhật Bản'}
            autoFocus={focusInput == 5}
          />
        </div>
      </div>
    );
  };

  //6
  const address1Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 6 ? 'focus-input' : ''} ${
            address1 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Tỉnh, thành phố</div>
          <input
            type="text"
            value={address1}
            onChange={(event) => {
              setAddress1(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(6);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 6}
          />
        </div>
      </div>
    );
  };

  //7
  const address2Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 7 ? 'focus-input' : ''} ${
            address2 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Quận, huyện</div>
          <input
            type="text"
            value={address2}
            onChange={(event) => {
              setAddress2(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(7);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 7}
          />
        </div>
      </div>
    );
  };

  //8
  const address3Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 8 ? 'focus-input' : ''} ${
            address3 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Xã, phường, thị trấn</div>
          <input
            type="text"
            value={address3}
            onChange={(event) => {
              setAddress3(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(8);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 8}
          />
        </div>
      </div>
    );
  };

  //9
  const address4Input = () => {
    return (
      <div className="address-common_input_wrapper add">
        <div
          className={`address-common_input ${focusInput == 9 ? 'focus-input' : ''} ${
            address4 ? 'validate-input' : ''
          }`}
        >
          <div className="address-common_input-title">Số nhà, tên đường</div>
          <input
            type="text"
            value={address4}
            onChange={(event) => {
              setAddress4(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(9);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 9}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{addressError}</div>
      </div>
    );
  };

  const addressComponent = () => {
    return (
      <div>
        <div className={`row justify-content-center p-2 m-0`}>
          <div className="col-12 m-0 p-0">
            <div className="address-component_child mt-2">
              <div className="add-cpn_child_column">
                {fullNameInput()}
                {phoneNumberInput()}
                {facebookInput()}
                {zaloInput()}
              </div>
              <div className="add-cpn_child_column">
                {zipCodeInput()}
                {address1Input()}
                {address2Input()}
                {address3Input()}
                {address4Input()}
              </div>
            </div>
          </div>
        </div>
        <div className={`row justify-content-center p-2 m-0`}>
          <div className="add-address_btn_container">
            <button
              className="dashboard_button_child mr-2 add-address"
              onClick={() => {
                receiverInfoId ? onPutReceiverInfo() : onPostReceiverInfo();
              }}
            >
              Lưu
            </button>
            <button
              className="dashboard_button_child ml-2 bg-secondary add-address"
              onClick={() => {console.log("Back")}}
            >
              Quay lại
            </button>
          </div>
        </div>
      </div>
    );
  };
  //End of component

  //useEffect
  useEffect(() => {
    if (receiverInfoId) {
      getReceiverIndoById(receiverInfoId)
        .then((data) => {
          setFullname(data.fullname);
          setPhoneNumber(data.phoneNumber);
          setZipCode(data.zipCode);
          setAddress1(data.address1);
          setAddress2(data.address2);
          setAddress3(data.address3);
          setAddress4(data.address4);
          setFacebook(data.facebook);
          setZalo(data.zalo);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getReceiverIndoById, receiverInfoId]);

  useEffect(() => {
    if (profile) {
      getNotification(1, 1)
        .then((data) => {
          setNotificationBadge(data.badge);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getNotification, profile, setNotificationBadge]);

  //Main
  return profile ? (
    <div className="container-fluid body dashboard-container tracking-container col-lg-11 col-12 p-0">
      <div className="tracking-title">
        {' '}
        {receiverInfoId ? 'Thay đổi địa chỉ nhận hàng' : 'Thêm địa chỉ nhận hàng'}
      </div>
      {addressComponent()}
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default ReceiverInfoDetail;

const StyledInput = styled.input`
  display: none;
`;
