import './Home.css';
import React from 'react';
import { useGetBanner, useGetRealm } from 'src/api/backend-api';
import { useEffect } from 'react';
import { useState } from 'react';
import Banner from 'src/components/Banner/Banner';
import { useAddPopup, useSetNotificationBadge } from 'src/state/application/hooks';
import { useGetNotification } from 'src/api/notificationApi';
import useProfile from 'src/hooks/useProfile';
import NavListComponent from 'src/components/NavListComponent/NavListComponent';
import { useGetAdvertisingBanner } from 'src/api/advertisingBannerApi';
import AdvertisingBanner from 'src/components/AdvertisingBanner/AdvertisingBanner';
import Categories from 'src/components/Categories/Categories';
import Realms from 'src/components/Realms/Realms';
import { CategoryType, RealmType } from 'src/api/models';
import { useGetCategory } from 'src/api/productCategoryApi';
import Products from 'src/components/Products/Products';

const Home: React.FC = () => {
  //Define
  const profile = useProfile();

  //State
  const [banners, setBanners] = useState([]);
  const [advertisingBannerList, setAdvertisingBannerList] = useState([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [category, setCategory] = useState<CategoryType>();
  const [realms, setRealms] = useState<RealmType[]>([]);
  const [realm, setRealm] = useState<RealmType>();
  
  //Function
  const getBanner = useGetBanner();
  const addPopup = useAddPopup();
  const getNotification = useGetNotification();
  const setNotificationBadge = useSetNotificationBadge();
  const getAdvertisingBanner = useGetAdvertisingBanner();
  const getRealm = useGetRealm();
  const getCategories = useGetCategory();



  useEffect(() => {
    getBanner().then(res => setBanners(res));
    getAdvertisingBanner().then((data) => setAdvertisingBannerList(data));
    getCategories().then(res => setCategories(res));
    getRealm().then(res => setRealms(res));
    if (profile) getNotification(1, 1).then(res => setNotificationBadge(res.badge));
  }, [profile]);


  //Main
  return (
    <>
      <div className="banner-home-container">
        <div className="banner-home-left-container">
          <Banner key="banner 1" listImages={banners} startNumber={1} />
        </div>
        <div className="banner-home-right-container d-none d-lg-flex">
          <AdvertisingBanner
            key="banner 2"
            listImages={advertisingBannerList}
            startNumber={1}
          />
          <AdvertisingBanner
            key="banner 3"
            listImages={advertisingBannerList}
            startNumber={2}
          />
        </div>
      </div>
      <NavListComponent />
      <Categories categories={categories} selectedCategory={category} onSelect={(item) => {
        setRealm(undefined);
        setCategory(!category || item.productCategoryId != category.productCategoryId ? item : undefined);
      }} />
      <Realms realms={realms} selectedRealm={realm} onSelect={(item) => {
        setCategory(undefined);
        setRealm(!realm || item.productRealmId != realm.productRealmId ? item : undefined);
      }} />
      <Products categoryId={category?.productCategoryId} realmId={realm?.productRealmId} />

    </>
  );
};

export default Home;
