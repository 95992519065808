import axios, { Method, ResponseType, AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import useProfile from 'src/hooks/useProfile';
import { useRemoveProfileInfo, useSetProfileInfo } from 'src/state/application/hooks';
import { useConfiguration } from '../contexts/ConfigProvider/ConfigProvider';
import {
  ChannelTypeInfo,
  CompanyInfoType,
  ImageType,
  NewsType,
  NfcInfo,
  PageData,
  ProductGiftType,
  ProductType,
  ProductSerialType,
  RealmType,
  StaticPageType,
  CommentType,
  ServiceType,
  TrackingType,
  ChangePasswordResult,
} from './models';
import { ProfileInfo } from 'src/state/application/models';

export type ApiRequestOption = {
  method: Method;
  body: any;
  responseType?: ResponseType;
  params?: any;
};

const instance = axios.create({});

export const useFetch = (anonymous?: boolean, file?: boolean, text?: boolean) => {
  const { backendUrl } = useConfiguration();
  const profile = useProfile();
  const removeProfileInfo = useRemoveProfileInfo();
  const setProfileInfo = useSetProfileInfo();

  const fetch = useCallback(
    async <T = any>(config: AxiosRequestConfig) => {
      const renewToken = async () => {
        const config: AxiosRequestConfig = {
          baseURL: backendUrl,
          responseType: 'json',
          params: {},
          headers: {
            'Content-Type': 'application/json;',
          },
          url: 'scidm/rest-api/security/renew-token',
          method: 'post',
          data: {
            token: profile?.accessToken,
            refreshToken: profile?.refreshToken,
          },
        };
        try {
          const res = await instance.request(config);
          return res.status == 200 ? res.data : undefined;
        } catch (e) {
          return undefined;
        }
      };

      const request = async (config: AxiosRequestConfig) => {
        return instance
          .request(config)
          .then((res) => res.data)
          .catch(async (e) => {
            if (e.response && e.response.status == 401) {
              const newToken = await renewToken();
              if (newToken) {
                setProfileInfo({
                  accessToken: newToken.token,
                  refreshToken: newToken.refreshToken,
                  info: profile?.info,
                });
                config.headers['Authorization'] = `Bearer ${newToken.token}`;
                const res = (await request(config)) as Promise<any>;
                return res;
              } else {
                removeProfileInfo();
                return e.response && e.response.data
                  ? Promise.reject(e.response.data)
                  : Promise.reject(e.response || new Error('General error'));
              }
            } else {
              return e.response && e.response.data
                ? Promise.reject(e.response.data)
                : Promise.reject(e.response || new Error('General error'));
            }
          });
      };

      if (!backendUrl) {
        return;
      }
      config.baseURL = backendUrl;
      config.responseType = text ? 'text' : 'json';
      config.params = {
        ...(config.params || {}),
      };
      if (!anonymous) {
        config.headers = {
          Authorization: `Bearer ${profile?.accessToken}`,
          'Content-Type': !file ? 'application/json;' : 'multipart/form-data;',
        };
      } else if (!config.headers) {
        config.headers = {
          'Content-Type': 'application/json;',
        };
      }

      return request(config) as Promise<T>;
    },
    [
      backendUrl,
      text,
      anonymous,
      profile?.accessToken,
      profile?.refreshToken,
      profile?.info,
      setProfileInfo,
      removeProfileInfo,
      file,
    ],
  );

  return fetch;
};

export const useFetchRaw = () => {
  const fetch = useCallback(async (config: AxiosRequestConfig) => {
    config.responseType = 'json';
    return axios
      .request(config)
      .then((res) => res.data)
      .catch((e) => {
        if (e.response && e.response.status === 400) {
          return Promise.reject(e.response.data);
        }
        return Promise.reject(e.response || new Error('General error'));
      });
  }, []);

  return fetch;
};

export const useChangePassword = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (email: string, currentPassword: string, password: string, confirmPassword: string) => {
      const item = {
        loginName: email,
        currentPassword: currentPassword,
        password: password,
        confirmPassword: confirmPassword,
      };
      return fetch({
        url: `scidm/rest-api/user/change-pass`,
        method: 'put',
        data: item,
      }) as Promise<ChangePasswordResult>;
    },
    [fetch],
  );
};

export const useForgotPassword = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (email: string, password: string, confirmPassword: string, otp: string) => {
      const item = {
        loginName: email,
        password: password,
        confirmPassword: confirmPassword,
        otp: otp,
      };
      return fetch({
        url: `scidm/rest-api/user/change-pass`,
        method: 'put',
        data: item,
      }) as Promise<ChangePasswordResult>;
    },
    [fetch],
  );
};

export const useGenOtp = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (email: string) => {
      return fetch({
        url: `scidm/rest-api/user/forgot/${email}`,
        method: 'post',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useReSentOtp = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (email: string) => {
      return fetch({
        url: `scidm/rest-api/user/otp/re-sent/${email}`,
        method: 'post',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useLoginWithEmail = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (loginName: string, password: string) => {
      return fetch({
        url: 'scidm/rest-api/security/login',
        method: 'post',
        data: {
          loginName,
          password,
        },
      }) as Promise<{ token: string; refreshToken: string }>;
    },
    [fetch],
  );
};

export const useLoginGoogle = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (googleAccessToken: string) => {
      return fetch({
        url: 'scidm/rest-api/security/login',
        method: 'post',
        data: {
          googleAccessToken,
        },
      }) as Promise<{ token: string; refreshToken: string }>;
    },
    [fetch],
  );
};

export const useRegisterWithEmail = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      email: string,
      loginName: string,
      fullName: string,
      password: string,
      confirmPassword: string,
    ) => {
      return fetch({
        url: 'scidm/rest-api/user/register',
        method: 'post',
        data: {
          email,
          loginName,
          fullName,
          password,
          confirmPassword,
        },
      }) as Promise<ProfileInfo>;
    },
    [fetch],
  );
};

export const useVerify = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (email: string, otp: string) => {
      return fetch({
        url: `scidm/rest-api/user/register-confirm-otp/${email}/${otp}`,
        method: 'post',
      }) as Promise<ProfileInfo>;
    },
    [fetch],
  );
};

export const useGetUserInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (accessToken: string) => {
      return fetch({
        url: 'scidm/rest-api/user/user-info',
        method: 'get',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }) as Promise<ProfileInfo>;
    },
    [fetch],
  );
};

export const useMyNfcs = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: 'sccore/rest-api/nfc',
      method: 'get',
    }) as Promise<NfcInfo[]>;
  }, [fetch]);
};

export const useGetNfcInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (key: string) => {
      return fetch({
        url: `sccore/rest-api/nfc/key/${key}`,
        method: 'get',
      }) as Promise<NfcInfo>;
    },
    [fetch],
  );
};

export const useAddUserNfcInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (key: string) => {
      return fetch({
        url: `sccore/rest-api/nfc/add-user/${key}`,
        method: 'post',
      }) as Promise<NfcInfo>;
    },
    [fetch],
  );
};

export const useUpdateNfcInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (key: string, data) => {
      return fetch({
        url: `sccore/rest-api/nfc/info/${key}`,
        method: 'post',
        data: data,
      }) as Promise<NfcInfo>;
    },
    [fetch],
  );
};

export const useUpdateNfcImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (key: string, data) => {
      return fetch({
        url: `sccore/rest-api/nfc/avatar/${key}`,
        method: 'post',
        data: data,
      }) as Promise<NfcInfo>;
    },
    [fetch],
  );
};

export const useGetChannelTypes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/channel-type`,
      method: 'get',
    }) as Promise<ChannelTypeInfo[]>;
  }, [fetch]);
};

/************************************/
/********                    ********/
/********         Realm      ********/
/******   Writen by Le Van Huy  ****/
/********                   ********/
/***********************************/

export const useGetRealm = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/product-realm`,
      method: 'get',
    }) as Promise<RealmType[]>;
  }, [fetch]);
};

export const useGetProductRealmById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productRealmId: number) => {
      return fetch({
        url: `sccore/rest-api/product-realm/${productRealmId}`,
        method: 'get',
        data: {
          productRealmId,
        },
      }) as Promise<RealmType>;
    },
    [fetch],
  );
};

export const usePostRealm = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      productRealmId: null,
      productRealmCode: string,
      productRealmName: string,
      displayOrder?: number,
    ) => {
      return fetch({
        url: 'sccore/rest-api/product-realm',
        method: 'post',
        data: {
          productRealmId,
          productRealmCode,
          productRealmName,
          displayOrder,
        },
      }) as Promise<RealmType>;
    },
    [fetch],
  );
};

export const usePutIdRealm = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      productRealmId: number,
      productRealmCode: string,
      productRealmName: string,
      displayOrder?: number,
    ) => {
      return fetch({
        url: `sccore/rest-api/product-realm`,
        method: 'put',
        data: {
          productRealmId,
          productRealmCode,
          productRealmName,
          displayOrder,
        },
      }) as Promise<RealmType>;
    },
    [fetch],
  );
};

export const useDeleteRealm = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productRealmId: number) => {
      return fetch({
        url: `sccore/rest-api/product-realm/${productRealmId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of Realm

/************************************/
/********                    ********/
/********      Product      ********/
/******   Writen by Le Van Huy  ****/
/********                   ********/
/***********************************/

export const useGetProduct = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      keyword: string,
      page: number,
      size: number,
      realmIds?: number[],
      categoryIds?: number[],
    ) => {
      let listRealms = '';
      let listCategorys = '';
      if (realmIds) {
        if (realmIds.length > 0) {
          realmIds.map((realmId: number) => {
            listRealms += realmId + ',';
          });
          listRealms = listRealms.slice(0, listRealms.length - 1);
        }
      }

      if (categoryIds) {
        if (categoryIds.length > 0) {
          categoryIds.map((categoryId: number) => {
            listCategorys += categoryId + ',';
          });
          listCategorys = listCategorys.slice(0, listCategorys.length - 1);
        }
      }

      return fetch({
        url: `sccore/rest-api/product?isStockRemain=true&${
          keyword !== null ? 'keyword=' + keyword + '&' : ''
        }page=${page}&size=${size}${
          realmIds ? (realmIds.length > 0 ? '&realmIds=' + listRealms : '') : ''
        }${categoryIds ? (categoryIds.length > 0 ? '&categoryIds=' + listCategorys : '') : ''}`,
        method: 'get',
      }) as Promise<PageData<ProductType>>;
    },
    [fetch],
  );
};

export const useGetHotProduct = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (page: number, size: number) => {
      //TODO
      return fetch({
        url: `sccore/rest-api/product?isStockRemain=true&hot=true&page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<ProductType>>;
    },
    [fetch],
  );
};

export const useGetListProductByIds = () => {
  const fetch = useFetch();
  return useCallback(
    (productIds: number[]) => {
      return fetch({
        url: `sccore/rest-api/product/ids`,
        method: 'post',
        data: productIds,
      }) as Promise<ProductType[]>;
    },
    [fetch],
  );
};

export const usePostProduct = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      productCategoryId: number,
      productCode: string,
      productName: string,
      price?: number,
      monthlySubscriptionFee?: number,
      phoneNumber?: string,
      specifications?: string,
      description?: string,
      cpu?: string,
      ram?: string,
      ssd?: string,
      hdd?: string,
      gpu?: string,
      display?: string,
    ) => {
      return fetch({
        url: 'sccore/rest-api/product',
        method: 'post',
        data: {
          productCategoryId,
          productCode,
          productName,
          price,
          monthlySubscriptionFee,
          phoneNumber,
          specifications,
          description,
          cpu,
          ram,
          ssd,
          hdd,
          gpu,
          display,
        },
      }) as Promise<ProductType>;
    },
    [fetch],
  );
};

export const usePutProduct = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      productId: number,
      productCategoryId: number,
      productCode: string,
      productName: string,
      price?: number,
      monthlySubscriptionFee?: number,
      phoneNumber?: string,
      specifications?: string,
      description?: string,
      cpu?: string,
      ram?: string,
      ssd?: string,
      hdd?: string,
      gpu?: string,
      display?: string,
    ) => {
      return fetch({
        url: 'sccore/rest-api/product',
        method: 'put',
        data: {
          productId,
          productCategoryId,
          productCode,
          productName,
          price,
          monthlySubscriptionFee,
          phoneNumber,
          specifications,
          description,
          cpu,
          ram,
          ssd,
          hdd,
          gpu,
          display,
        },
      }) as Promise<ProductType>;
    },
    [fetch],
  );
};

export const useGetProductById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productId: number) => {
      return fetch({
        url: `sccore/rest-api/product/${productId}`,
        method: 'get',
      }) as Promise<ProductType>;
    },
    [fetch],
  );
};

export const useUpdateProductAvatar = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (id: number, data) => {
      return fetch({
        url: `sccore/rest-api/product/avatar/${id}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useAddProductImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (id: string, data) => {
      return fetch({
        url: `sccore/rest-api/product/image/${id}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteProduct = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productId: number) => {
      return fetch({
        url: `sccore/rest-api/product/${productId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useDeleteProductImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productId: number, fileId: number) => {
      return fetch({
        url: `sccore/rest-api/product/${productId}/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

//Display option
export const usePutProductGift = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productId: number, listGift: ProductGiftType[]) => {
      return fetch({
        url: `sccore/rest-api/product/${productId}/gift`,
        method: 'put',
        data: listGift,
      }) as Promise<ProductGiftType[]>;
    },
    [fetch],
  );
};

//Display option
export const useGetProductGift = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productId: number) => {
      return fetch({
        url: `sccore/rest-api/product/${productId}/gift`,
        method: 'get',
      }) as Promise<ProductGiftType[]>;
    },
    [fetch],
  );
};

//Display option
export const useCopyProductGift = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (toId: number, fromId: number) => {
      return fetch({
        url: `sccore/rest-api/product/${toId}/copy-gift/${fromId}`,
        method: 'post',
        data: {},
      }) as Promise<ProductGiftType[]>;
    },
    [fetch],
  );
};
//End of Product

/************************************/
/********                    ********/
/******   Product Serial     ********/
/******   Writen by Le Van Huy  ****/
/********                   ********/
/***********************************/

export const useGetProductSerial = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productId: number, keyword: string, page: number, size: number, status: number) => {
      return fetch({
        url: `sccore/rest-api/product-serial/product/${productId}?${
          keyword !== null ? 'keyword=' + keyword + '&' : ''
        }page=${page}&size=${size}${
          status !== 0 ? (status === 1 ? '&status=1' : '&status=2') : ''
        }`,
        method: 'get',
      }) as Promise<PageData<ProductSerialType>>;
    },
    [fetch],
  );
};

export const useGetProductSerialByProductId = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productId: number) => {
      return fetch({
        url: `sccore/rest-api/product-serial/product/${productId}`,
        method: 'get',
      }) as Promise<PageData<ProductSerialType>>;
    },
    [fetch],
  );
};

//Đang làm dở
export const usePostProductSerial = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      productId: number,
      // useProductImage: boolean, //Chưa có thông tin
      serial: string,
      price?: number,
      monthlySubscriptionFee?: number,
      phoneNumber?: string,
      specifications?: string,
      description?: string,
      cpu?: string,
      ram?: string,
      ssd?: string,
      hdd?: string,
      gpu?: string,
      display?: string,
    ) => {
      return fetch({
        url: 'sccore/rest-api/product-serial',
        method: 'post',
        data: {
          productId,
          // useProductImage,
          serial,
          price,
          monthlySubscriptionFee,
          phoneNumber,
          specifications,
          description,
          cpu,
          ram,
          ssd,
          hdd,
          gpu,
          display,
        },
      }) as Promise<ProductSerialType>;
    },
    [fetch],
  );
};

//oke
export const useGetProductSerialById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productSerialId: number) => {
      return fetch({
        url: `sccore/rest-api/product-serial/${productSerialId}`,
        method: 'get',
      }) as Promise<ProductSerialType>;
    },
    [fetch],
  );
};

//oke
export const usePutProductSerial = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      productSerialId: number,
      productId: number,
      serial: string,
      price?: number,
      monthlySubscriptionFee?: number,
      phoneNumber?: string,
      specifications?: string,
      description?: string,
      cpu?: string,
      ram?: string,
      ssd?: string,
      hdd?: string,
      gpu?: string,
      display?: string,
    ) => {
      return fetch({
        url: 'sccore/rest-api/product-serial',
        method: 'put',
        data: {
          productSerialId,
          productId,
          serial,
          price,
          monthlySubscriptionFee,
          phoneNumber,
          specifications,
          description,
          cpu,
          ram,
          ssd,
          hdd,
          gpu,
          display,
        },
      }) as Promise<ProductSerialType>;
    },
    [fetch],
  );
};

//oke
export const useUpdateProductSerialAvatar = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (id: number, data) => {
      return fetch({
        url: `sccore/rest-api/product-serial/avatar/${id}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

//Writing
export const useAddProducSerialImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (id: number, data) => {
      return fetch({
        url: `sccore/rest-api/product-serial/image/${id}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteProductSerialImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productSerialId: number, fileId: number) => {
      return fetch({
        url: `sccore/rest-api/product-serial/${productSerialId}/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useDeleteProductSerial = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productSerialId: number) => {
      return fetch({
        url: `sccore/rest-api/product-serial/${productSerialId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useSellProductSerial = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (productSerialId: number) => {
      return fetch({
        url: `sccore/rest-api/product-serial/${productSerialId}/sell`,
        method: 'put',
        data: {
          productSerialId,
        },
      }) as Promise<ProductSerialType>;
    },
    [fetch],
  );
};

//End of Product serial

/************************************/
/********                    ********/
/******       Banner       ********/
/******   Writen by Le Van Huy  ****/
/********                   ********/
/***********************************/

export const useGetBanner = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/banner`,
      method: 'get',
    }) as Promise<ImageType[]>;
  }, [fetch]);
};

export const useAddBannerImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (data) => {
      return fetch({
        url: `sccore/rest-api/banner`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteBannerImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (fileId: number) => {
      return fetch({
        url: `sccore/rest-api/banner/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of banner

/************************************/
/********                    ********/
/******    Company image     ********/
/******   Writen by Le Van Huy  ****/
/********                   ********/
/***********************************/

export const useGetCompanyImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/company-image`,
      method: 'get',
    }) as Promise<ImageType[]>;
  }, [fetch]);
};

export const useAddCompanyImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (data) => {
      return fetch({
        url: `sccore/rest-api/company-image`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteCompanyImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (fileId: number) => {
      return fetch({
        url: `sccore/rest-api/company-image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of company image

/************************************/
/********                    ********/
/********    Company info    ********/
/******   Writen by Le Van Huy  ****/
/********                   ********/
/***********************************/

export const useGetCompanyInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/company-info`,
      method: 'get',
    }) as Promise<CompanyInfoType[]>;
  }, [fetch]);
};

export const useGetCompanyInfoByKey = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (key: string) => {
      return fetch({
        url: `sccore/rest-api/company-info/key/${key}`,
        method: 'get',
      }) as Promise<CompanyInfoType>;
    },
    [fetch],
  );
};

export const usePostCompanyInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      companyInfoKey: string,
      companyInfoTitle: string,
      companyInfoValue: string,
      href?: string,
    ) => {
      return fetch({
        url: 'sccore/rest-api/company-info',
        method: 'post',
        data: {
          companyInfoKey,
          companyInfoTitle,
          companyInfoValue,
          href,
        },
      }) as Promise<CompanyInfoType>;
    },
    [fetch],
  );
};

export const useGetCompanyInfoById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (companyInfoId: number) => {
      return fetch({
        url: `sccore/rest-api/company-info/${companyInfoId}`,
        method: 'get',
      }) as Promise<CompanyInfoType>;
    },
    [fetch],
  );
};

export const usePutCompanyInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      companyInfoId: number,
      companyInfoKey: string,
      companyInfoTitle: string,
      companyInfoValue,
      href?: string,
    ) => {
      return fetch({
        url: `sccore/rest-api/company-info`,
        method: 'put',
        data: {
          companyInfoId,
          companyInfoKey,
          companyInfoTitle,
          companyInfoValue,
          href,
        },
      }) as Promise<CompanyInfoType>;
    },
    [fetch],
  );
};

export const useDeleteCompanyInfo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (companyInfoId: number) => {
      return fetch({
        url: `sccore/rest-api/company-info/${companyInfoId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of company info

/************************************/
/********                    ********/
/********    Static Page    ********/
/******   Writen by LuanPT  ****/
/********                   ********/
/***********************************/

export const useGetStaticPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/static-page`,
      method: 'get',
    }) as Promise<StaticPageType[]>;
  }, [fetch]);
};

export const usePostStaticPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      staticPageKey: string,
      staticPageTitle: string,
      staticPageValue: string,
      description: string,
    ) => {
      return fetch({
        url: 'sccore/rest-api/static-page',
        method: 'post',
        data: {
          staticPageKey,
          staticPageTitle,
          staticPageValue,
          description,
        },
      }) as Promise<StaticPageType>;
    },
    [fetch],
  );
};

export const useGetStaticPageById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (staticPageId: number) => {
      return fetch({
        url: `sccore/rest-api/static-page/${staticPageId}`,
        method: 'get',
      }) as Promise<StaticPageType>;
    },
    [fetch],
  );
};

export const useGetStaticPageByKey = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (key: string) => {
      return fetch({
        url: `sccore/rest-api/static-page/key/${key}`,
        method: 'get',
      }) as Promise<StaticPageType>;
    },
    [fetch],
  );
};

export const usePutStaticPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      staticPageId: number,
      staticPageKey: string,
      staticPageTitle: string,
      staticPageValue: string,
      description: string,
    ) => {
      return fetch({
        url: `sccore/rest-api/static-page`,
        method: 'put',
        data: {
          staticPageId,
          staticPageKey,
          staticPageTitle,
          staticPageValue,
          description,
        },
      }) as Promise<StaticPageType>;
    },
    [fetch],
  );
};

export const useDeleteStaticPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (staticPageId: number) => {
      return fetch({
        url: `sccore/rest-api/static-page/${staticPageId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useGetStaticPageImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/static-page/image`,
      method: 'get',
    }) as Promise<ImageType[]>;
  }, [fetch]);
};

export const useAddStaticPageImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (data) => {
      return fetch({
        url: `sccore/rest-api/static-page/image`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteStaticPageImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (fileId: number) => {
      return fetch({
        url: `sccore/rest-api/static-page/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of static page

/************************************/
/********                    ********/
/********        News        ********/
/******   Writen by LuanPT  ****/
/********                   ********/
/***********************************/

export const useGetNews = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (page?: number, size?: number) => {
      return fetch({
        url: `sccore/rest-api/news${page ? '?page=' + page : ''}${size ? '&size=' + size : ''}`,
        method: 'get',
      }) as Promise<PageData<NewsType>>;
    },
    [fetch],
  );
};

export const usePostNews = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (newTitle: string, newValue: string, newAvatar: string, description: string) => {
      return fetch({
        url: 'sccore/rest-api/news',
        method: 'post',
        data: {
          newTitle,
          newValue,
          newAvatar,
          description,
        },
      }) as Promise<NewsType>;
    },
    [fetch],
  );
};

export const useGetNewsById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (newId: number) => {
      return fetch({
        url: `sccore/rest-api/news/${newId}`,
        method: 'get',
      }) as Promise<NewsType>;
    },
    [fetch],
  );
};

export const usePutNews = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (
      newId: number,
      newTitle: string,
      newValue: string,
      newAvatar: string,
      description: string,
    ) => {
      return fetch({
        url: `sccore/rest-api/news`,
        method: 'put',
        data: {
          newId,
          newTitle,
          newValue,
          newAvatar,
          description,
        },
      }) as Promise<NewsType>;
    },
    [fetch],
  );
};

export const useDeleteNews = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (staticPageId: number) => {
      return fetch({
        url: `sccore/rest-api/news/${staticPageId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useGetNewsImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/news/image`,
      method: 'get',
    }) as Promise<ImageType[]>;
  }, [fetch]);
};

export const useAddNewsImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (data) => {
      return fetch({
        url: `sccore/rest-api/news/image`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteNewsImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (fileId: number) => {
      return fetch({
        url: `sccore/rest-api/news/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useGetAllComment = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (newsId: number) => {
      return fetch({
        url: `sccore/rest-api/news/${newsId}/comment`,
        method: 'get',
      }) as Promise<CommentType[]>;
    },
    [fetch],
  );
};

export const useGetCommentByCommentId = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (commentId: number) => {
      return fetch({
        url: `sccore/rest-api/news/parent/${commentId}/comment`,
        method: 'get',
      }) as Promise<CommentType[]>;
    },
    [fetch],
  );
};

export const usePostNewComment = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (comment: CommentType) => {
      return fetch({
        url: `sccore/rest-api/news/comment`,
        method: 'post',
        data: comment,
      }) as Promise<CommentType>;
    },
    [fetch],
  );
};

//End of news

/************************************/
/********                    ********/
/******       Logo       ********/
/******   Writen by LuanPT      ****/
/********                   ********/
/***********************************/

export const useGetLogos = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/logo`,
      method: 'get',
    }) as Promise<ImageType[]>;
  }, [fetch]);
};

export const useGetLogo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/logo/final`,
      method: 'get',
    }) as Promise<ImageType>;
  }, [fetch]);
};

export const useAddLogoImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch(false, true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (data) => {
      return fetch({
        url: `sccore/rest-api/logo`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteLogoImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (fileId: number) => {
      return fetch({
        url: `sccore/rest-api/logo/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of logo

/************************************/
/********                    ********/
/******       Service      ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

export const useGetAllService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/service`,
      method: 'get',
    }) as Promise<ServiceType[]>;
  }, [fetch]);
};

export const useGetServiceById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (serviceId: number) => {
      return fetch({
        url: `sccore/rest-api/service/${serviceId}`,
        method: 'get',
      }) as Promise<ServiceType>;
    },
    [fetch],
  );
};

export const usePostService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (service: ServiceType) => {
      return fetch({
        url: `sccore/rest-api/service`,
        method: 'post',
        data: service,
      }) as Promise<ServiceType>;
    },
    [fetch],
  );
};

export const usePutService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (_service: ServiceType) => {
      return fetch({
        url: `sccore/rest-api/service`,
        method: 'put',
        data: _service,
      }) as Promise<ServiceType>;
    },
    [fetch],
  );
};

export const useDeleteService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (serviceId: number) => {
      return fetch({
        url: `sccore/rest-api/service/${serviceId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of service

/************************************/
/********                    ********/
/******       Tracking      ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/
export const useGetOrderTracking = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (trackingId: string) => {
      return fetch({
        url: `sccore/rest-api/tracking`,
        method: 'post',
        data: {
          trackingId: trackingId,
        },
      }) as Promise<TrackingType>;
    },
    [fetch],
  );
};
//End of tracking
