import './ProductDetail.css';
import React from 'react';
import { useParams } from 'react-router';
import {
  useGetProductById,
  useGetProductGift,
  useGetStaticPageByKey,
} from 'src/api/backend-api';
import { useEffect, useState } from 'react';
import {
  CategoryAttributeType,
  ProductGiftType,
  ProductType,
  StaticPageType,
  CategoryType,
  EnumDataType,
  ImageType,
  EnumDisplayConfig,
} from 'src/api/models';
import {
  useAddPopup,
  useInsertCart,
  useGetCart,
  useEditCart,
  useNotifyChat,
  useGetProfileInfo,
} from 'src/state/application/hooks';
import {
  useGetCategoryDisplayOption,
  useGetProductCategoryById,
} from 'src/api/productCategoryApi';
import ProductBanner from 'src/components/ProductBanner/ProductBanner';
import { NavLink, useNavigate } from 'react-router-dom';
import { number2money } from 'src/utils/stringUtils';
import Products from 'src/components/Products/Products';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { OptionExtraType, OptionType } from 'src/state/application/models';
import { useGetConsulationRoom } from 'src/api/roomApi';

enum EnumBoolean {
  true = 1,
  false = 0,
}

const ProductDetail: React.FC = () => {
  //Value
  const params = useParams<{ productId: string }>();
  const productId = Number(params.productId);
  const cart = useGetCart();

  const [listProductGift, setListProductGift] = useState<ProductGiftType[]>([]);

  const [product, setProduct] = useState<ProductType>();
  const [productCategory, setProductCategory] = useState<CategoryType>();

  const [staticPage, setStaticPage] = useState<StaticPageType>();
  const [bodyCommit, setBodyCommit] = useState<StaticPageType>();
  const [bodyAddress, setBodyAddress] = useState<StaticPageType>();

  const [descriptions, setDescriptions] = useState<CategoryAttributeType[]>([]);
  const [options, setOptions] = useState<OptionExtraType[]>([]);
  const [selecetedOption, setSelecetedOption] = useState<{ [name: string]: string }>({});
  const [images, setImages] = useState<ImageType[]>([]);

  //Function
  const getProductCategoryById = useGetProductCategoryById();
  const getProductById = useGetProductById();
  const getListConfig = useGetCategoryDisplayOption();
  const getProductGift = useGetProductGift();
  const addPopup = useAddPopup();
  const getStaticPageByKey = useGetStaticPageByKey();

  const notifyChat = useNotifyChat();
  const profile = useGetProfileInfo();
  const getConsulationRoom = useGetConsulationRoom();
  const navigate = useNavigate();
  const insertCart = useInsertCart();

  const editCart = useEditCart();

  const onInsertCart = () => {
    if (!product.isStockRemain) {
      addPopup({
        error: {
          message: 'Hết hàng',
          title: 'Thêm vào giỏ hàng thất bại',
        },
      });
    }
    if (product.isHiddenSerial) {
      const strOption = JSON.stringify(selecetedOption);
      const cartItem = cart.find(c => c.productId == productId && c.option == strOption);
      if (cartItem) {
        if(product.stockRemainQuantity - cartItem.quantity > 0) {
          const nCartItem = { ...cartItem };
          nCartItem.quantity++;
          editCart(nCartItem); 
        } else {
          addPopup({
            txn: {
              success: false,
              summary: 'Số lượng chọn trong giỏ hàng không được vượt quá số lượng tồn kho!',
            },
          });
        }

      } else {
        insertCart({
          productId: productId,
          quantity: 1,
          option: strOption
        });
        addPopup({
          txn: {
            success: true,
            summary: 'Thêm vào giỏ hàng thành công!',
          },
        });
      }
    }
  };
  //End of function


  //TODO

  const stockRemainComponent = () => {
    return (
      <div className="product-detail-intro">
        Số lượng:&nbsp;<span style={{ color: '#2b80dd', fontWeight: 700 }}>{product?.stockRemainQuantity}</span>
      </div>
      
    );
  };

  const productGiftComponent = () => {
    return listProductGift.length > 0 ? (
      <div className="product-detail-promotion">
        <div className="box-promotion">
          <i className="fas fa-gift"></i> &nbsp;
          <span>Gifts and promotions</span>
        </div>
        {listProductGift.map((productGift: ProductGiftType) => {
          return (
            <div>
              <input type="checkbox" checked />
              <span>{productGift.productGiftValue}</span>
            </div>
          );
        })}
      </div>
    ) : null;
  };


  const bodyCommitComponent = () => {
    return (
      <div className="product-detail-top-child-container mt-4">
        {bodyCommit ? (
          <div
            dangerouslySetInnerHTML={{
              __html: bodyCommit?.staticPageValue??''
            }}
          />
        ) : null}
      </div>
    );
  };

  const bodyAddressComponent = () => {
    return (
      <div className="product-detail-top-child-container mt-4">
        {bodyAddress ? (
          <div
            dangerouslySetInnerHTML={{
              __html: bodyAddress?.staticPageValue??''
            }}
          />
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    getProductGift(productId).then((productGift) => setListProductGift(productGift));
    getStaticPageByKey('BODY_COMMIT').then((bodyCommit) => setBodyCommit(bodyCommit));
    getStaticPageByKey('BODY_ADDRESS').then((bodyAddress) => setBodyAddress(bodyAddress));
  }, [getProductGift, getStaticPageByKey, productId]);

  useEffect(() => {
    getProductById(productId).then((product) => setProduct(product));
  }, [getProductById, productId]);

  useEffect(() => {
    if (product) {
      getProductCategoryById(product.productCategoryId).then((data) => setProductCategory(data));
      getListConfig(product.productCategoryId).then((configRes) => {
        setDescriptions(configRes.filter(c => c.isShowProductSerialDetail == EnumBoolean.true && c.attribute.attributeType == EnumDataType.Text));
        const nOptions = [];
        const nSelectedOption = {};
        configRes.filter(c => c.isShowProductSerialDetail == EnumBoolean.true && c.attribute.attributeType == EnumDataType.Option)
          .map(config => {
            const value = eval(`product?.${config.attributeName}??''`);
            if (value) {
              const option = JSON.parse(value) as OptionType;

              const optionExtra: OptionExtraType = {
                ...option,
                name: config.attributeName,
                title: config.attributeTitle
              };
              nOptions.push(optionExtra);
              nSelectedOption[config.attributeName] = option.values[0];
            }
          });
        setOptions(nOptions);
        setSelecetedOption(nSelectedOption);
      });
      if (product.introContent !== '' && product.introContent !== null)
        getStaticPageByKey(product.introContent).then((staticPage) => setStaticPage(staticPage));
    }
  }, [getProductCategoryById, product, getListConfig, getStaticPageByKey]);


  useEffect(() => {
    let lstImages = product?.images ?? [];
    options.forEach(option => {
      if (selecetedOption[option.name]
        && option.images
        && option.images[selecetedOption[option.name]]
        && option.images[selecetedOption[option.name]].length > 0) {
        lstImages = lstImages.filter(i => option.images[selecetedOption[option.name]].includes(i.fileId));
      }
    });

    setImages(lstImages);
  }, [product, options, selecetedOption])

  //Main
  return (
    <>
      <PageContainer>
        <PageHeader>
          <NavLink to={'/'}>Trang chủ</NavLink>{` / `}
          <NavLink to={`/product/category/${productCategory?.productCategoryId}`}>{productCategory?.productCategoryName}</NavLink>
          {product ? `/ ${product.productName} (${product.productCode})` : ''} 
        </PageHeader>
        <div className="product-detail-content">
          <ProductBanner listImages={images} startNumber={1} />
          <div className="product-detail-info">
            <div className='product-name'>{product?.productName} ({product?.productCode})</div>
            <div className="product-detail-price inline">
              {product?.discountPercent ? <div className="product_pc_price_discount">
                {number2money(Math.round((product.price / (100 - product.discountPercent)) * 100))}
              </div> : null}
              {number2money(product?.price)}
            </div>
            {options.map((option, index) => {
              return (
                <div key={`productdetailoption${index}`}>
                  <div>{option.title}</div>
                  <div className='option-content'>
                    {option.values.map((value, index) => {
                      const lstImages = product?.images ?? [];
                      const img = option.images[value]?.length > 0 ? lstImages.find(i => option.images[value].includes(i.fileId)) : undefined;
                      return (
                        <div key={`optionvalue${index}`}
                          className={`option-value ${selecetedOption[option.name] == value ? 'option-active' : ''}`}
                          onClick={() => {
                            const nSelecetedOption = { ...selecetedOption };
                            nSelecetedOption[option.name] = value;
                            setSelecetedOption(nSelecetedOption);
                          }}
                        >
                          {img ? <><img src={img.thumbUrl} /><span>{value}</span></> : <label>{value}</label>}
                        </div>
                      );

                    })}
                  </div>
                </div>
              )
            })}

            {descriptions.map((config: CategoryAttributeType, index: number) => {
              return (
                <div className='product-attribute' key={`productdetailattribute${index}`}>
                  <div className='product-attribute-title' >{config.attributeTitle}:</div>
                  {product ? (
                    <div><b>{eval(`product?.${config.attributeName}??'N/A'`)}</b></div>
                  ) : null}
                </div>
              );
            })}

            {productGiftComponent()}

            <div className="product-detail-add-to-cart-container">
              <div
                className="btn-add-to-cart"
                onClick={() => {
                  onInsertCart();
                }}
              >
                <div>THÊM VÀO GIỎ HÀNG</div>
                <div>Giao hàng hoặc nhận hàng tại cửa hàng</div>
              </div>
              <div
                className="btn-add-to-cart"
                onClick={() => {
                  if (profile?.accessToken) {
                    getConsulationRoom().then((res) => {
                      notifyChat(res.roomId, {
                        name: product.productName,
                        description: descriptions.length > 0 ? eval(`product?.${descriptions[0].attributeName}??''`) : '',
                        url: product.thumbAvatar,
                        message: 'Vui lòng gửi cho tôi thông tin sản phẩm',
                        functionId: EnumDisplayConfig.San_pham,
                        objectId: productId
                      });
                    });
                  } else {
                    window.scrollTo(0, 0);
                    navigate(`/auth-screen`);
                  }
                }}
              >
                <div>HỖ TRỢ</div>
                <div>Liên hệ hỗ trợ khách hàng</div>
              </div>
            </div>

            {stockRemainComponent()}
            {bodyCommitComponent()}
            {bodyAddressComponent()}
          </div>

        </div>
        {staticPage ? <>
          <div
            dangerouslySetInnerHTML={{
              __html: eval(`staticPage??''`),
            }}
          />
        </> : null}
      </PageContainer>
      <div className='related-products-title'>SẢN PHẨM TƯƠNG TỰ</div>
      <Products categoryId={product?.productCategoryId} isHorizontally={true} />
    </>
  );
};

export default ProductDetail;
