import './Checkouts.css';
import React, { useEffect, useRef, useState } from 'react';
import {
  useAddPopup,
  useCleanCart,
  useGetCart,
  useSetNotificationBadge,
} from 'src/state/application/hooks';
import {
  EnumReceiveTime,
  EnumReceiveTimeTitle,
  OrderRequirementDetailsItemType,
  OrderRequirementType,
  ReceiverInfoType,
} from 'src/api/models';
import { CartItemType } from 'src/state/application/models';
import { useDeleteReceiverInfo, useGetMyReceiverInfo, useGetReceiverInfoById, usePostReceiverInfo, usePutReceiverInfo } from 'src/api/receiverInfoApi';
import CartReceiverInfoComponent from 'src/components/CartReceiverInfoComponent/CartReceiverInfoComponent';
import { useGetShipInfo } from 'src/api/commonInfomationApi';
import Calendar, { EnumCalendarAlign, EnumCalendarPos } from 'src/components/Calendar/Calendar';
import useProfile from 'src/hooks/useProfile';
import { Navigate, useNavigate } from 'react-router';
import Images from 'src/assets/img';
import { usePostOrderRequirement } from 'src/api/orderRequirementApi';
import { useGetNotification } from 'src/api/notificationApi';
import { useReadQRCode } from 'src/api/qrApi';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { normalizationZipcode, number2money } from 'src/utils/stringUtils';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import Cart from 'src/components/Cart/Cart';
import Input from 'src/components/Input/Input';
import SelectSearch from 'src/components/SelectSearch/SelectSearch';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';
import OptionComponent from 'src/components/OptionComponent/OptionComponent';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

const Checkouts: React.FC = () => {
  //Value
  const cart = useGetCart();
  const profile = useProfile();
  const navigate = useNavigate();

  //Receiver
  const [userId, setUserId] = useState<number>();
  const [receiverFullName, setReceiverFullName] = useState<string>('');
  const [receiverFullnameError, setReceiverFullnameError] = useState<string>();

  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<string>('');

  const [distance, setDistance] = useState<string>();
  const [receiverZipCode, setReceiverZipCode] = useState<string>('');
  const [receiverZipCodeError, setReceiverZipCodeError] = useState<string>();

  const [receiverAddress1, setReceiverAddress1] = useState<string>('');
  const [receiverAddress1Error, setReceiverAddress1Error] = useState<string>();
  const [receiverAddress2, setReceiverAddress2] = useState<string>('');
  const [receiverAddress3, setReceiverAddress3] = useState<string>('');
  const [receiverAddress4, setReceiverAddress4] = useState<string>('');
  const [receiverAddressError, setReceiverAddressError] = useState<string>();

  const [receiverFacebook, setReceiverFacebook] = useState<string>('');
  const [receiverZalo, setReceiverZalo] = useState<string>('');
  const [receiverContactError, setReceiverContactError] = useState<string>();

  const [orderRequirementNote, setOrderRequirementNote] = useState<string>('');

  const [receiverTime1, setReceiverTime1] = useState<EnumReceiveTime>(EnumReceiveTime.Any);
  const [receiverDate1, setReceiverDate1] = useState<string>();
  const [receiverTime2, setReceiverTime2] = useState<EnumReceiveTime>(EnumReceiveTime.Any);
  const [receiverDate2, setReceiverDate2] = useState<string>();

  const [receiverInfoList, setReceiverInfoList] = useState<ReceiverInfoType[]>([]);

  const [shipFee, setShipFee] = useState<number>();
  const [totalMoney, setTotalMoney] = useState(0);

  const [checkReceiverId, setCheckReceiverId] = useState<number>();

  const [collapse, setCollapse] = useState(false);
  //const [isShip, setIsShip] = useState(false);
  //End of state

  //Function
  const getMyReceiverInfo = useGetMyReceiverInfo();
  const postOrderRequirement = usePostOrderRequirement();
  const addPopup = useAddPopup();
  const cleanCart = useCleanCart();
  const getNotification = useGetNotification();
  const setNotificationBadge = useSetNotificationBadge();
  const postReceiverInfo = usePostReceiverInfo();
  const putReceiverInfo = usePutReceiverInfo();
  const deleteReceiverInfo = useDeleteReceiverInfo();
  const getShipInfo = useGetShipInfo();

  const receiveTimes = [
    {
      value: EnumReceiveTime.Any,
      title: EnumReceiveTimeTitle.Any
    },
    {
      value: EnumReceiveTime.Morning,
      title: EnumReceiveTimeTitle.Morning
    },
    {
      value: EnumReceiveTime.Twelve_Fifteen,
      title: EnumReceiveTimeTitle.Twelve_Fifteen
    },
    {
      value: EnumReceiveTime.Fifteen_Eightteen,
      title: EnumReceiveTimeTitle.Fifteen_Eightteen
    },
    {
      value: EnumReceiveTime.Eightteen_TwentyOne,
      title: EnumReceiveTimeTitle.Eightteen_TwentyOne
    }
  ]


  const checkFuncReceiver = (item: ReceiverInfoType) => {
    if(checkReceiverId != item.receiverInfoId) {
      setUserId(item.userId);
      setCheckReceiverId(item.receiverInfoId);
      setReceiverFullName(item.fullname);
      setReceiverPhoneNumber(item.phoneNumber);
      setReceiverZipCode(item.zipCode);
      setReceiverAddress1(item.address1);
      setReceiverAddress2(item.address2);
      setReceiverAddress3(item.address3);
      setReceiverAddress4(item.address4);
      setReceiverFacebook(item.facebook);
      setReceiverZalo(item.zalo);
      onChangeZipCode(item.zipCode);
    } else {
      setCheckReceiverId(undefined);
    }

  };

  const onPostReceiverInfo = () => {
    if (validateReceiverFullName()
      && validateReceiverContact()
      && validateReceiverAddress()) {
      const receiverInfo: ReceiverInfoType = {
        fullname: receiverFullName,
        phoneNumber: receiverPhoneNumber,
        zipCode: receiverZipCode,
        address1: receiverAddress1,
        address2: receiverAddress2,
        address3: receiverAddress3,
        address4: receiverAddress4,
        facebook: receiverFacebook,
        zalo: receiverZalo
      };
      postReceiverInfo(receiverInfo)
        .then((res) => {
          setReceiverInfoList([...receiverInfoList, res]);
          setCheckReceiverId(res.receiverInfoId);
          setReceiverFullName(res.fullname);
          setReceiverPhoneNumber(res.phoneNumber);
          setReceiverZipCode(res.zipCode);
          setReceiverAddress1(res.address1);
          setReceiverAddress2(res.address2);
          setReceiverAddress3(res.address3);
          setReceiverAddress4(res.address4);
          setReceiverFacebook(res.facebook);
          setReceiverZalo(res.zalo);
          addPopup({
            txn: {
              success: true,
              summary: 'Tạo mới địa chỉ giao hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.message,
              title: 'Tạo mới địa chỉ giao hàng thất bại!',
            },
          });
        });
    }
  };

  const onPutReceiverInfo = () => {
    if (validateReceiverFullName()
      && validateReceiverContact()
      && validateReceiverZipcode()
      && validateReceiverAddress()) {
      const receiverInfo: ReceiverInfoType = {
        fullname: receiverFullName,
        phoneNumber: receiverPhoneNumber,
        zipCode: receiverZipCode,
        address1: receiverAddress1,
        address2: receiverAddress2,
        address3: receiverAddress3,
        address4: receiverAddress4,
        facebook: receiverFacebook,
        zalo: receiverZalo,
        receiverInfoId: checkReceiverId,
        userId: userId
      };
      putReceiverInfo(receiverInfo)
        .then((res) => {
          const indx = receiverInfoList.findIndex(r => r.receiverInfoId == checkReceiverId);
          receiverInfoList[indx] = res;
          setReceiverInfoList([...receiverInfoList]);
          setReceiverFullName(res.fullname);
          setReceiverPhoneNumber(res.phoneNumber);
          setReceiverZipCode(res.zipCode);
          setReceiverAddress1(res.address1);
          setReceiverAddress2(res.address2);
          setReceiverAddress3(res.address3);
          setReceiverAddress4(res.address4);
          setReceiverFacebook(res.facebook);
          setReceiverZalo(res.zalo);
          addPopup({
            txn: {
              success: true,
              summary: 'Thay đổi địa chỉ giao hàng thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.message,
              title: 'Thay đổi địa chỉ giao hàng thất bại',
            },
          });
        });
    }
  };

  const onDeleteReceiverInfo = () => {
    deleteReceiverInfo(checkReceiverId).then(() => {
      const nReceiverInfos = receiverInfoList.filter(r => r.receiverInfoId != checkReceiverId);
      setCheckReceiverId(undefined);
      setReceiverInfoList([...nReceiverInfos]);
    });
  };


  //Validate
  const validateReceiverFullName = () => {
    const bOk = receiverFullName && receiverFullName != '';
    setReceiverFullnameError(!bOk ? 'Chưa nhập tên người nhận' : undefined);
    return bOk;
  };

  const validateReceiverAddress = () => {
    const bOk =
      (receiverAddress2 && receiverAddress2 != '') ||
      (receiverAddress3 && receiverAddress3 != '') ||
      (receiverAddress4 && receiverAddress4 != '');
    setReceiverAddressError(!bOk ? 'Chưa nhập địa chỉ giao hàng. Vui lòng kiểm tra lại' : undefined);
    return bOk;
  };

  const validateReceiverContact = () => {
    const bOk =
      (receiverPhoneNumber && receiverPhoneNumber != '') ||
      (receiverFacebook && receiverFacebook != '') ||
      (receiverZalo && receiverZalo != '');
    setReceiverContactError(
      !bOk ? 'Chưa nhập thông tin liên hệ, vui lòng nhập số điện thoại, thông tin facebook hoặc zalo' : undefined,
    );
    return bOk;
  };

  const validateReceiverZipcode = () => {
    const bOk =
      (receiverZipCode && receiverZipCode != '');
    setReceiverZipCodeError(
      !bOk ? 'Chưa nhập mã bưu điện, cần nhập mã bưu điện để tính phí vận chuyển' : undefined,
    );
    return bOk;
  };
  //End of validate

  const onPostOrderRequirement = () =>  new Promise((resolve) => {
    const isReceiverFullname = validateReceiverFullName();
    const isReceiverAddress = validateReceiverAddress();
    const isReceiverContact = validateReceiverContact();
    const isReceiverZipcode = validateReceiverZipcode();
    if (isReceiverFullname && isReceiverAddress && isReceiverContact && isReceiverZipcode) {
      if (shipFee != -1) {
        const orderRequirementDetails: OrderRequirementDetailsItemType[] = [];
        cart.map((item: CartItemType) => {
          const detail: OrderRequirementDetailsItemType = {
            productId: item.productId,
            quantity: item.quantity
          };
          orderRequirementDetails.push(detail);
        });
        const oderRequirement: OrderRequirementType = {
          receiverFullname: receiverFullName,
          receiverPhoneNumber: receiverPhoneNumber,
          receiverZipCode: receiverZipCode,
          receiverAddress1: receiverAddress1,
          receiverAddress2: receiverAddress2,
          receiverAddress3: receiverAddress3,
          receiverAddress4: receiverAddress4,
          receiverFacebook: receiverFacebook,
          receiverTime1: receiverTime1,
          receiverDate1: receiverDate1,
          receiverTime2: receiverTime2,
          receiverDate2: receiverDate2,
          orderRequirementNote: orderRequirementNote,
          orderRequirementDetails: orderRequirementDetails,
        };
        postOrderRequirement(oderRequirement)
          .then(() => {
            navigate('/order-requirement');
            addPopup({
              txn: {
                success: true,
                summary: 'Đơn hàng của bạn đã được gửi thành công, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất',
              },
            });
            cleanCart();
            resolve(true);
          })
          .catch((error) => {
            addPopup({
              error: {
                message: error.errorMessage,
                title: 'Một lỗi đã xảy ra. Vui lòng liên hệ nhân viên hỗ trợ.',
              },
            });
            resolve(false);
          });
      } else {
        addPopup({
          txn: {
            success: false,
            summary: 'Địa chỉ giao hàng không nằm trong khu vực được hỗ trợ. Rất mong quý khách thông cảm!',
          },
        });
        resolve(false);
      }
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Vui lòng nhập đủ thông tin giao hàng.',
        },
      });
      resolve(false);
    }
  });
  //End of function

  const onChangeZipCode = (zipcode: string) => {
    setReceiverZipCode(zipcode);
    const nZipcode = normalizationZipcode(zipcode);
    if (!nZipcode || nZipcode.length != 7) return;
    getShipInfo(nZipcode)
      .then((data) => {
        setReceiverAddress1(data?.pref);
        setReceiverAddress2(data?.city);
        setReceiverAddress3(data?.town);
        setDistance(`${data.distance} km`);
        setShipFee(data?.shipFee);
      })
      .catch(() => {
        setReceiverAddress1(undefined);
        setReceiverAddress2(undefined);
        setReceiverAddress3(undefined);
        setDistance(undefined);
        setShipFee(undefined);
      });
  };

  useEffect(() => {
    getMyReceiverInfo()
      .then((data) => {
        setReceiverInfoList(data);
      });
  }, [getMyReceiverInfo]);

  useEffect(() => {
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yy = date.getFullYear();
    const str = `${dd}/${mm}/${yy}`;
    setReceiverDate1(str);
    setReceiverDate2(str);
  }, []);

  useEffect(() => {
    if (profile) {
      getNotification(1, 1)
        .then((data) => {
          setNotificationBadge(data.badge);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getNotification, profile, setNotificationBadge]);



  //Main
  return (
    profile ? cart.length > 0 ?
      <PageContainer>
        <PageHeader>
          <NavLink to={'/'}>Home</NavLink>{` / `}Đặt hàng
        </PageHeader>
        <div className="checkout-container">
          <div className={`cart-info`}>
            <div className="cart-info-header">
              <div><span className="material-icons cart-info-header-middle">shopping_cart</span><label className="cart-info-header-middle">{cart.length} sản phẩm</label> </div>
              <span className="material-icons cart-info-header-middle" onClick={() => setCollapse(!collapse)}>{collapse ? 'arrow_drop_down' : 'arrow_drop_up'}</span>
            </div>
            <div className={collapse ? 'collapse' : 'uncollapse'}>
              <Cart ref={(el: any) => {
                if (el) setTotalMoney(el.getSubTotal());
              }} />
              {shipFee ? <div className='money-title'><label>Khoảng cách</label><span>{distance}</span></div> : null}
              <div className='money-title'><label>Phí vận chuyển</label><span className={shipFee == -1? 'too-far' : ''}>{shipFee ? shipFee != -1 ? number2money(shipFee) : 'Quá xa' : 'Tính toán sau khi nhập thông tin giao hàng'}</span></div>
              <hr />
              <div className='total-title'><label> Tổng tiền</label><span>{shipFee >= 0? number2money(totalMoney + shipFee) : 'N/A'}</span></div>
            </div>
           
          </div>
          <div className="delivery">
            <div className="receiver-info">
              {receiverInfoList.length > 0 && <label>Chọn một trong các địa chỉ dưới đây hoặc nhập thông tin giao hàng của bạn</label>}
              <div className="receiver-info-list">
                {receiverInfoList.map((value, index) => {
                  return (
                    <CartReceiverInfoComponent
                      key={`receiveritem${index}`}
                      data={value}
                      checked={value.receiverInfoId == checkReceiverId}
                      checkFunc={() => checkFuncReceiver(value)}
                    />
                  );
                })}
              </div>
              {checkReceiverId ? <label className='delete-address' onClick={onDeleteReceiverInfo}>Xóa địa chỉ giao hàng</label> : null }
            </div>
            <div className="delivery-info">
              <div className="delivery-address">
                <div>1. Địa chỉ giao hàng</div>
                <Input
                  leftIcon='fas fa-user'
                  placeholder='Tên người nhận'
                  value={receiverFullName}
                  errorMessage={receiverFullnameError}
                  type="text"
                  onChange={setReceiverFullName}
                  onBlur={validateReceiverFullName}
                />
                <Input
                  leftIcon='fas fa-phone'
                  placeholder='Số điện thoại'
                  value={receiverPhoneNumber}
                  type="text"
                  onChange={setReceiverPhoneNumber}
                  onBlur={validateReceiverContact}
                />
                <Input
                  leftIcon='fab fa-facebook-f'
                  rightIcon='fas fa-info-circle'
                  rightAction={() => window.open('/static-page/FACEBOOK_INFO_GUIDE')}
                  placeholder='Facebook'
                  value={receiverFacebook}
                  type="text"
                  onChange={setReceiverFacebook}
                  onBlur={validateReceiverContact}
                />
                <Input
                  leftIcon='fa-solid fa-z'
                  rightIcon='fas fa-info-circle'
                  rightAction={() => window.open('/static-page/ZALO_INFO_GUIDE')}
                  placeholder='Zalo'
                  value={receiverZalo}
                  errorMessage={receiverContactError}
                  type="text"
                  onChange={setReceiverZalo}
                  onBlur={validateReceiverContact}
                />
                <Input
                  leftIcon='fab fa-product-hunt'
                  placeholder='Mã bưu điện (bắt buộc)'
                  value={receiverZipCode}
                  type="text"
                  onChange={onChangeZipCode}
                  errorMessage={receiverZipCodeError}
                  onBlur={validateReceiverZipcode}
                />
                <Input
                  leftIcon='fa-solid fa-map-pin'
                  placeholder='Tỉnh'
                  value={receiverAddress1}
                  type="text"
                  onChange={setReceiverAddress1}
                  onBlur={validateReceiverAddress}
                />
                <Input
                  leftIcon='fas fa-map-marker-alt'
                  placeholder='Thành phố'
                  value={receiverAddress2}
                  type="text"
                  onChange={setReceiverAddress2}
                  onBlur={validateReceiverAddress}
                />
                <Input
                  leftIcon='fas fa-map-marked-alt'
                  placeholder='Thị trấn'
                  value={receiverAddress3}
                  type="text"
                  onChange={setReceiverAddress3}
                  onBlur={validateReceiverAddress}
                />
                <Input
                  leftIcon='fas fa-building'
                  placeholder='Đường, tòa nhà,...'
                  value={receiverAddress4}
                  errorMessage={receiverAddressError}
                  type="text"
                  onChange={setReceiverAddress4}
                  onBlur={validateReceiverAddress}
                />
                <label className='save-address' onClick={checkReceiverId ? onPutReceiverInfo : onPostReceiverInfo}>Lưu thông tin giao hàng</label>
              </div>
              <div className="delivery-time">
                <div>2. Thời gian nhận hàng</div>
                <fieldset className='priority-delivery-time'>
                  <legend className='delivery-title'>Thời gian nhận hàng 1 <span style={{ color: 'red' }}>*</span>{' '}</legend>
                  <div className="receiver-time-container">
                    <CalendarInput value={receiverDate1} onChange={setReceiverDate1} />
                    <OptionComponent width='150px' value={receiverTime1} data={receiveTimes} onChange={setReceiverTime1} titleType='title' valueType='value' />
                  </div>
                </fieldset>
                <fieldset className='priority-delivery-time'>
                  <legend className='delivery-title'>Thời gian nhận hàng 2</legend>
                  <div className="receiver-time-container">
                    <CalendarInput value={receiverDate2} onChange={setReceiverDate2} />
                    <OptionComponent width='150px' value={receiverTime2} data={receiveTimes} onChange={setReceiverTime2} titleType='title' valueType='value' />
                  </div>

                </fieldset>
                <textarea
                  className='note-checkout'
                  value={orderRequirementNote}
                  onChange={(event) => setOrderRequirementNote(event.target.value)}
                  placeholder={'Note...'}
                  rows={3}
                />
                <ButtonComponent loader={true} onClick={onPostOrderRequirement} icon={'shopping_cart_checkout'} title='ĐẶT HÀNG' background='#f37e1f' colorhover='black' />
              </div>
            </div>
          </div>

        </div>
      </PageContainer >
      : <Navigate to="/" />
      : <Navigate to="/auth-screen" />
  );

};

export default Checkouts;
