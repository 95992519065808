/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { MessageInfo, RoomType } from 'src/api/models';
import { useGetRoomById, useGetRoomByUser } from 'src/api/roomApi';
import {
  useCleanChat,
  useCleanNotifyChat,
  useGetChat,
  useGetNotifyChat,
  useNotifyChat,
} from 'src/state/application/hooks';
import ChatRoom from '../ChatRoom/ChatRoom';
import './Chat.css';
import { useConfiguration } from 'src/contexts/ConfigProvider/ConfigProvider';
import useProfile from 'src/hooks/useProfile';
import { EnumCloseCodes, EnumMessageDataType, EnumMessageType } from 'src/common/enum/EnumWebsocket';
import { v4 as uuidv4 } from 'uuid';

interface IChat {
  setConsultationAvailable: (consultationAvailable: boolean) => void;
}

const Chat: React.FC<IChat> = (props) => {
  const profile = useProfile();
  const [rooms, setRooms] = useState<RoomType[]>([]);
  const interval = useRef(undefined);
  const socket = useRef<WebSocket>(undefined);
  const { backendWs } = useConfiguration();
  
  const receiveRoom = useGetNotifyChat();
  const cleanNotifyChat = useCleanNotifyChat();

  const chatUser = useGetChat();
  const cleanChat = useCleanChat();

  const getRoomByUser = useGetRoomByUser();
  const getRoomById = useGetRoomById();
  const notifyChat = useNotifyChat();
  
  const openSocket = () => {
    console.log('Connect community room... ');
    const deviceId = uuidv4();
    socket.current = new WebSocket(
      `${backendWs}/community-room?access_token=${profile.accessToken}&device_id=${deviceId}`,
    );
    socket.current.onopen = () => {
      console.log('Connect success');
      interval.current = setInterval(() => {
        console.log("ping server");
        if (socket.current?.readyState)
          socket.current?.send(JSON.stringify({
            type: EnumMessageType.Ping,
          }));
      }, 4 * 60 * 1000);


    };
    socket.current.onmessage = (event) => socketOnMessage(event);
    socket.current.onerror = (err) => console.log(err);
    socket.current.onclose = (event) => {
      console.log("OnClose", event.code, event.reason);
    }
  }

  const socketOnMessage = (event: MessageEvent) => {
		
		console.log('Socket on message: type: ' + event.type + ', data: ' + event.data);
		const data = JSON.parse(event.data) as MessageInfo;
    if(data.type == EnumMessageType.ChatNotify) {
      notifyChat(data.data.value);
    }
		
	};


  const close = () => {
    if (socket?.current) socket.current.close(EnumCloseCodes.NORMAL_CLOSURE, "Thoát");
    if (interval?.current) clearInterval(interval.current);
  };


  useEffect(() => {
    if(profile) openSocket();
    return () => {
      if(profile) close();
    };
  }, [])

  useEffect(() => {
    if (receiveRoom) {
      const indx = rooms.findIndex((r) => r.roomId === receiveRoom.roomId);
      if (indx < 0) {
        getRoomById(receiveRoom.roomId).then((data: RoomType) => {
          const newRooms = [...rooms, { ...data, extendData: receiveRoom.extendData }];
          setRooms(newRooms);
        });
      } else {
        rooms[indx].isCollapse = false;
        rooms[indx].extendData = receiveRoom.extendData;
        setRooms([...rooms]);
      }
    }
    cleanNotifyChat();
  }, [receiveRoom]);

  useEffect(() => {
    if (chatUser) {
      getRoomByUser(chatUser).then((data: RoomType) => {
        const indx = rooms.findIndex((r) => r.roomId === data.roomId);
        if (indx < 0) {
          const newRooms = [...rooms, data];
          setRooms(newRooms);
        } else if (rooms[indx].isCollapse) {
          rooms[indx].isCollapse = false;
          setRooms([...rooms]);
        }
      });
    }
    cleanChat();
  }, [chatUser]);

  useEffect(() => {
    props.setConsultationAvailable(rooms.length == 0);
  }, [props, rooms]);


  const removeRoom = (roomId: string) => {
    const index = rooms.findIndex((r) => r.roomId == roomId);
    if (index >= 0) rooms.splice(index, 1);
    setRooms([...rooms]);
  };

  const collapseRoom = (roomId: string) => {
    const room = rooms.find((r) => r.roomId == roomId);
    room.isCollapse = true;
    setRooms([...rooms]);
  };

  const unCollapseRoom = (roomId: string) => {
    const room = rooms.find((r) => r.roomId == roomId);
    room.isCollapse = false;
    setRooms([...rooms]);
  };

  const chatRomComponent = () => {
    let collapseIndex = -1;
    let index = -1;

    return rooms.map((room) => {
      if (room.isCollapse) {
        collapseIndex++;
      } else {
        index++;
      }
      return (
        <ChatRoom
          key={room.roomId}
          room={room}
          index={index}
          isCollapse={room.isCollapse}
          collapseIndex={collapseIndex}
          removeRoom={removeRoom}
          collapse={collapseRoom}
          unCollapse={unCollapseRoom}
        />
      );
    });
  };

  return <div className="chat-container">{chatRomComponent()}</div>;
};

export default Chat;

