import './NavListComponent.css';
import React from 'react';
import NavItemComponent, { INavItemComponent } from './NavItemComponent/NavItemComponent';
import Images from 'src/assets/img';

const NavListComponent: React.FC = () => {
  //Define
  const navItemList: INavItemComponent[] = [
    {
      avatar: Images.van_chuyen,
      name: 'Vận chuyển',
      url: '/static-page/EXPRESS_SERVICE',
      newTab: false,
    },
    {
      avatar: Images.internet_icon,
      name: 'Lắp đặt internet',
      url: '/static-page/INTERNET_SERVICE',
      newTab: false,
    },
    {
      avatar: Images.download_app,
      name: 'Tải ứng dụng',
      url: 'https://app.jvscorp.jp',
      newTab: true,
    },
    {
      avatar: Images.tracking_icon,
      name: 'Tracking',
      url: '/tracking',
      newTab: false,
    },
    {
      avatar: Images.news_icon,
      name: 'Tin tức',
      url: '/news/default',
      newTab: false,
    },
  ];

  //Component
  const mainComponent = () => {
    return (
      <div className="nav-list-cpn">
        {navItemList.map((value, index) => {
          return (
            <NavItemComponent
              key={`nav-link-${index}`}
              avatar={value.avatar}
              name={value.name}
              url={value.url}
              newTab={value.newTab}
            />
          );
        })}
      </div>
    );
  };

  //Main
  return (
    <div className={`body-component-container`}>
      <div className="container-fluid p-0 m-0">{mainComponent()}</div>
    </div>
  );
};

export default NavListComponent;
