import './OrderRequirementDetail.css';
import React, { useEffect, useState } from 'react';
import { useAddPopup, useChangeLoadingFlag, useLoadingFlag, useSetNotificationBadge } from 'src/state/application/hooks';
import {
  EnumDisplayConfig,
  EnumOrderRequirementProgressStatus,
  EnumOrderRequirementProgressStatusTitle,
  EnumReceiveTime,
  EnumReceiveTimeTitle,
  NoteByDay,
  NoteType,
  OrderRequirementDetailsItemType,
  OrderRequirementType,
} from 'src/api/models';
import useProfile from 'src/hooks/useProfile';
import { Navigate, useNavigate } from 'react-router';
import Images from 'src/assets/img';
import { NavLink, useParams } from 'react-router-dom';
import { useGetOrderRequirementById } from 'src/api/orderRequirementApi';
import { useGetNote, usePostNote } from 'src/api/noteApi';
import { number2money } from 'src/utils/stringUtils';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import Products from 'src/components/Products/Products';
import Input from 'src/components/Input/Input';
import CartItem from 'src/components/CartItem/CartItem';
import { nanoid } from '@reduxjs/toolkit';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import useLogo from 'src/hooks/useLogo';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';
import Loading from 'src/components/Loading';

const OrderRequirementDetail: React.FC = () => {
  //Value
  const profile = useProfile();
  const navigate = useNavigate();
  const params = useParams<{ orderRequirementId: string }>();
  const orderRequirementId = Number(params.orderRequirementId);

  //Receiver
  const [data, setData] = useState<OrderRequirementType>();
  const [subTotalMoney, setSubTotalMoney] = useState<number>(null);
  const [collapse, setCollapse] = useState(false);

  const [noteList, setNoteList] = useState<NoteByDay[]>([]);
  const [noteContent, setNoteContent] = useState('');
  const [reloadFlag, setReloadFlag] = useState<boolean>(false);
  //End of state
  const logo = useLogo();
  //Function
  const addPopup = useAddPopup();
  const getOrderRequirementById = useGetOrderRequirementById();
  const getNote = useGetNote();
  const postNote = usePostNote();
  const changeLoadingFlag = useChangeLoadingFlag();
  const loadingFlag = useLoadingFlag();

  const onPostNote = () => {
    if (orderRequirementId > 0 && noteContent != '') {
      const note: NoteType = {
        functionId: EnumDisplayConfig.Danh_sach_dat_hang,
        objectId: orderRequirementId,
        noteContent: noteContent,
      };
      changeLoadingFlag(true);
      postNote(note, profile.info.userId)
        .then((res) => {
          addPopup({
            txn: {
              success: true,
              summary: 'Send successfully',
            },
          });
          setNoteContent('');
          const nNoteList = [...noteList];
          if (nNoteList.length == 0) {
            nNoteList.push({
              createdAt: (new Date()).toLocaleDateString("en-US"),
              list: [res]
            })
          } else {
            nNoteList[0].list.splice(0, 0, res);
          }
          setNoteList(nNoteList);
        })
        .catch((e) => {
          addPopup({
            error: {
              title: 'An error has occurred',
              message: e.errorMessage,
            },
          });
          setReloadFlag(!reloadFlag);
        })
        .finally(() => changeLoadingFlag(false));
    }
  };
  //End of function




  //End of component

  useEffect(() => {
    if (orderRequirementId > 0) {
      changeLoadingFlag(true);
      Promise.all([
        getOrderRequirementById(orderRequirementId),
        getNote(EnumDisplayConfig.Danh_sach_dat_hang, orderRequirementId)
      ]).then(([orderRes, noteRes]) => {
        console.log(orderRes.orderRequirementDetails);
        setData(orderRes)
        const subTotal = orderRes.orderRequirementDetails.reduce((total, detail) => total += (detail.quantity ?? 0) * (detail.product?.price ?? 0), 0);
        setSubTotalMoney(subTotal);
        let createDay: string = undefined;
        const notes: NoteByDay[] = [];
        noteRes.forEach(item => {
          if (item.createdAt == createDay) {
            notes[notes.length - 1].list.push(item);
          } else {
            createDay = item.createdAt;
            const noteOfDay = {
              createdAt: createDay,
              list: [] as NoteType[]
            };
            noteOfDay.list.push(item);
            notes.push(noteOfDay);
          }
        })
        setNoteList(notes);
      }).catch((error) => {
        addPopup({
          error: {
            title: 'An error has occurred',
            message: error.errorMessage,
          },
        });
      }).finally(() => changeLoadingFlag(false));
    } else {
      window.scrollTo(0, 0);
      navigate(-1);
    }
  }, [addPopup, changeLoadingFlag, getNote, getOrderRequirementById, navigate, orderRequirementId]);

  //Main
  return (profile ?
    <>
      <PageContainer>
        <PageHeader>
          <NavLink to={'/'}>Home</NavLink>{` / `}<NavLink to={'/order-requirement'}>Order list</NavLink>{` / `}Order infomation
        </PageHeader>
        <div className="order-requirement-detail-container">
          <div className={`order-cart-info`}>
            <div className="order-cart-info-header">
              <div>{loadingFlag ? <Loading /> : <span className="material-icons order-cart-info-header-middle">shopping_cart</span>}<label className="order-cart-info-header-middle">{data?.orderRequirementDetails.length} items</label> </div>
              <span className="material-icons order-cart-info-header-middle hidden" onClick={() => setCollapse(!collapse)}>{collapse ? 'arrow_drop_down' : 'arrow_drop_up'}</span>
            </div>
            <div className={collapse ? 'collapse' : 'uncollapse'}>
              {data?.orderRequirementDetails?.length > 0 ? data.orderRequirementDetails.map((detail, index) => {
                return (
                  <CartItem
                    key={`cartitem${index}`}
                    cartItem={{
                      productId: detail.productId,
                      quantity: detail.quantity,
                      key: nanoid()
                    }}
                    product={detail.product}
                    isDisable={true}
                  />
                );
              }) : null}
              <div className='order-money-title'><label>Tiền hàng</label><span>{number2money(subTotalMoney ?? 0)}</span></div>
              <div className='order-money-title'><label>Phí vận chuyển</label><span>{number2money(data?.shipFee)}</span></div>
              <hr />
              <div className='order-total-title'><label> Tổng cộng</label><span>{number2money((subTotalMoney ?? 0) + (data?.shipFee ?? 0))}</span></div>
            </div>

          </div>
          <div className="order-delivery-info">
            <div>1. Delivery address</div>
            <Input
              leftIcon='fas fa-user'
              placeholder='Fullname'
              value={data?.receiverFullname}
              type="text"
              isDisabled={true}
            />
            <Input
              leftIcon='fas fa-phone'
              placeholder='Phone'
              value={data?.receiverPhoneNumber}
              isDisabled={true}
              type="text"
            />
            <Input
              leftIcon='fab fa-facebook-f'
              rightIcon='fas fa-chevron-circle-right'
              rightAction={() => window.open(data?.receiverFacebook)}
              placeholder='Facebook'
              value={data?.receiverFacebook}
              isDisabled={true}
              type="text"
            />
            <Input
              leftIcon='fab fa-product-hunt'
              placeholder='Postal code (optional)'
              value={data?.receiverZipCode}
              isDisabled={true}
              type="text"
            />
            <Input
              leftIcon='fas fa-globe'
              placeholder='Country / region'
              value={data?.receiverAddress1}
              isDisabled={true}
              type="text"
            />
            <Input
              leftIcon='fas fa-map-marker-alt'
              placeholder='City'
              value={data?.receiverAddress2}
              isDisabled={true}
              type="text"
            />
            <Input
              leftIcon='fas fa-map-marked-alt'
              placeholder='Address'
              value={data?.receiverAddress3}
              isDisabled={true}
              type="text"
            />
            <Input
              leftIcon='fas fa-building'
              placeholder='Apartment, suite, etc.'
              value={data?.receiverAddress4}
              isDisabled={true}
              type="text"
            />
            <div>2. Priority delivery time</div>
            <fieldset className='order-priority-delivery-time'>
              <legend className='order-delivery-title'>First priority time <span style={{ color: 'red' }}>*</span>{' '}</legend>
              <div className="order-receiver-time-container">
                <CalendarInput value={data?.receiverDate1} isDisabled={true} />
                <div className='order-receiver-time-option'>{EnumReceiveTimeTitle[EnumReceiveTime[data?.receiverTime1]]}</div>

              </div>
            </fieldset>
            <fieldset className='order-priority-delivery-time'>
              <legend className='order-delivery-title'>Second priority time</legend>
              <div className="order-receiver-time-container">
                <CalendarInput value={data?.receiverDate2} isDisabled={true} />
                <div className='order-receiver-time-option'>{EnumReceiveTimeTitle[EnumReceiveTime[data?.receiverTime2]]}</div>
              </div>

            </fieldset>
            <textarea
              className='order-note-checkout'
              value={data?.orderRequirementNote}
              placeholder={'Note...'}
              disabled={true}
              rows={3}
            />
          </div>
          <div className="order-receiver-info">
            <div className="cart-oder-title order-status">3.Trạng thái đơn hàng
              {data?.progressStatus == EnumOrderRequirementProgressStatus.Waiting ? (
                <div style={{ color: 'gray' }}>
                  <i className="fas fa-spinner"></i>&ensp;
                  {EnumOrderRequirementProgressStatusTitle.Waiting}
                </div>
              ) : data?.progressStatus == EnumOrderRequirementProgressStatus.Sent ? (
                <div style={{ color: 'green' }}>
                  <i className="fas fa-shipping-fast"></i>&ensp;
                  {EnumOrderRequirementProgressStatusTitle.Sent}
                </div>
              ) : data?.progressStatus == EnumOrderRequirementProgressStatus.Error ? (
                <div style={{ color: 'red' }}>
                  <i className="fas fa-exclamation"></i>&ensp;
                  {EnumOrderRequirementProgressStatusTitle.Error}
                </div>
              ) : null}
            </div>
            <div className="cart-oder-title">4.Trao đổi với admin</div>

            <textarea
              className='order-note-checkout'
              placeholder='Enter the text of the exchange on the line'
              value={noteContent}
              onChange={(event) => {
                setNoteContent(event.target.value);
              }}
              rows={3}
            />
            <ButtonComponent onClick={onPostNote} icon={'chat'} title='SEND' />
            <hr />
            {noteList.map((dayNotes) => {
              return (
                <div className="note-component">
                  {dayNotes.list.map((note, index) => {
                    return (note.userId == profile.info.userId ?
                      <div className={`my-note-item`} key={`noteitem${index}`}>
                        {note.noteContent}
                      </div> : <div className={`note-item`} key={`noteitem${index}`}>
                        <img className="note-avatar" src={note.avataUrl ?? logo} alt="avatar" title='note.fullName' />
                        <div className="note-detail">{note.noteContent}</div>
                      </div>
                    );
                  })}
                  <div className="note-date">
                    <div className="note-line"></div>
                    <div className="note-datetime">{dayNotes.createdAt}</div>
                    <div className="note-line"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </PageContainer >

      <div className='related-products-title'>PRODUCTS</div>
      <Products isHorizontally={true} />
    </>
    : <Navigate to="/auth-screen" />
  );
};

export default OrderRequirementDetail;
