export enum EnumCloseCodes {
  NORMAL_CLOSURE = 1000,
  CANNOT_ACCEPT = 1003,
  CLOSED_ABNORMALLY = 1006,
}

export enum EnumMessageDataType {
  Text = 1,
  Image = 2,
  Consultation = 3,
}

export enum EnumMessageType {
  Exchange = 1,
  Ping = 2,
  Online = 3,
  Connect = 4,
  Disconnect = 5,
  ChatNotify = 6,
}
