import './NotificationScrollView.css';
import React from 'react';
import {
  EnumDisplayConfig,
  EnumNotificationStatus,
  EnumNotiTemplateKey,
  NotificationType,
} from 'src/api/models';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetNotification, useReadNotificationById } from 'src/api/notificationApi';
import { useSetNotificationBadge } from 'src/state/application/hooks';
import { useNavigate } from 'react-router-dom';
import Images from 'src/assets/img';

const NotificationScrollView: React.FC = () => {
  //Value
  const navigate = useNavigate();
  const SIZE = 20;

  //State
  const [notificationList, setNotificationList] = useState<NotificationType[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadNotiFlag, setLoadNotiFlag] = useState(false);

  //Function
  const getNotification = useGetNotification();
  const readNotificationById = useReadNotificationById();
  const setNotificationBadge = useSetNotificationBadge();

  const onReadById = (notificationId: number) => {
    readNotificationById(notificationId)
      .then(() => {
        setLoadNotiFlag(!loadNotiFlag);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotificationUrl = (templateKey: string, notificationData: string) => {
    let url: string;
    let newTab;
    const extendData = JSON.parse(notificationData);
    switch (templateKey) {
      case EnumNotiTemplateKey.NOTE:
        switch (extendData.functionId) {
          case EnumDisplayConfig.Sim_order:
            url = `https://don.jvscorp.jp/ordered-list/${extendData.objectId}`;
            newTab = true;
            break;
          case EnumDisplayConfig.Sim_da_ban:
            url = `https://don.jvscorp.jp/active-sim-list/${extendData.objectId}`;
            newTab = true;
            break;
          case EnumDisplayConfig.ProductOrder:
            url = `https://order.jvscorp.jp/product-order/detail?productOrderId=${extendData.objectId}`;
            newTab = true;
            break;
          case EnumDisplayConfig.ShipOrder:
            url = `https://order.jvscorp.jp/ship-order/detail?shipOrderId=${extendData.objectId}`;
            newTab = true;
            break;
          case EnumDisplayConfig.PurchaseAccount:
            url = `https://order.jvscorp.jp/account/purchase-account/edit?tid=${extendData.objectId}`;
            newTab = true;
            break;
          case EnumDisplayConfig.Danh_sach_dat_hang:
            url = `/order-requirement/${extendData.objectId}`;
            newTab = false;
            break;
        }
        break;
      case EnumNotiTemplateKey.REPORT_SIM_ERROR:
      case EnumNotiTemplateKey.STOP_SERVICE:
        url = `https://don.jvscorp.jp/active-sim-list/${extendData.simId}`;
        newTab = true;
        break;

      case EnumNotiTemplateKey.CREATE_SHIP_ORDER:
      case EnumNotiTemplateKey.UPDATE_SHIP_ORDER:
        {
          url = `https://order.jvscorp.jp/ship-order/detail?shipOrderId=${extendData?.shipOrderId}`;
          newTab = true;
        }
        break;
      case EnumNotiTemplateKey.CREATE_PRODUCT_ORDER:
      case EnumNotiTemplateKey.UPDATE_PRODUCT_ORDER:
        url = `https://order.jvscorp.jp/product-order/detail?productOrderId=${extendData.productOrderId}`;
        newTab = true;
        break;
      case EnumNotiTemplateKey.CREATE_ORDER_REQUIREMENT:
        url = `/order-requirement/${extendData.orderRequirementId}`;
        newTab = false;
        break;
    }

    return { url, newTab };
  };

  const onCheck = (
    notificationTemplateKey: EnumNotiTemplateKey,
    notificationData: string,
    notificationId: number,
  ) => {
    const { url, newTab } = getNotificationUrl(notificationTemplateKey, notificationData);
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.scrollTo(0, 0);
      navigate(url);
    }
    onReadById(notificationId);
  };

  const fetchData = () => {
    getNotification(SIZE, page)
      .then((data) => {
        const temp = notificationList.filter(() => true);
        const d = data.data.items;
        const arr = temp.concat(d);
        setCount(data.data.count);
        setNotificationList(arr);
        if (arr.length >= count) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setPage(page + 1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //Component
  const noNotificationComponent = () => {
    return (
      <div className="cart-value p-3">
        <div className="container-fluid mt-100">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body cart">
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img
                      src={Images.notification_img}
                      width="200"
                      height="200"
                      className="img-fluid mb-4 mr-3"
                    />
                    <h3>
                      <strong>Không có thông báo</strong>
                    </h3>
                    <h4>Chúc bạn chọn được món hàng yêu thích!</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //useEffect
  useEffect(() => {
    getNotification(SIZE, 1)
      .then((r) => {
        setPage(2);
        setNotificationList(r.data.items);
        setCount(r.data.count);
        setNotificationBadge(r.badge);

        if (r.data.count > SIZE) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((error) => {
        console.log('notification screen error');
        console.log(error);
      });
  }, [getNotification, setNotificationBadge, loadNotiFlag]);

  //Main
  return notificationList.length > 0 ? (
    <div className={`body-component-container`}>
      <div className="body-list-main-mobile-container d-flex" id="scrollableMobileDiv">
        <InfiniteScroll
          dataLength={notificationList.length}
          next={fetchData}
          hasMore={hasMore}
          style={{ display: 'flex', flexDirection: 'column' }}
          scrollableTarget="scrollableMobileDiv"
          loader={<div>Đang tải</div>}
          endMessage={''}
        >
          {notificationList
            ? notificationList.map((value: NotificationType) => {
                return (
                  <div
                    className={`noti-component ${
                      value.status == EnumNotificationStatus.Read ? 'read' : ''
                    }`}
                    onClick={() => {
                      onCheck(
                        value.notificationTemplateKey,
                        value.notificationData,
                        value.notificationId,
                      );
                    }}
                  >
                    <div className="noti-avt">
                      {value.avataUrl ? (
                        <img src={value.avataUrl} alt="avt" />
                      ) : (
                        <div className="noti-avt-null">avt</div>
                      )}
                    </div>
                    <div className="noti-value">
                      <div className="noti-title">
                        <div>{value.notificationTitle}</div>
                        <div style={{ fontSize: 12 }}>{value.createdAt}</div>
                      </div>
                      <div className="noti-main">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: value.notificationValue,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </InfiniteScroll>
      </div>
    </div>
  ) : (
    noNotificationComponent()
  );
};

export default NotificationScrollView;
