import loginImage from './img-dk-mobile.png';
import logo from './logo.png';
import loading_icon from './icon-loading.gif';
import tracking from './tracking.png';
import login_img from './login-img.png';
import cart_img from './cart-img.png';
import notification_img from './notification-img.png';
import empty_list from './empty-list.png';
import download_app from './tai_ung_dung.png';
import tin_tuc from './tin_tuc.png';
import van_chuyen from './van_chuyen.png';
import tracking_icon from './tracking_icon.png';
import internet_icon from './internet_icon.png';
import news_icon from './news_icon.png';
import noimage from './noimage.png';

const Images = {
  loginImage: loginImage,
  logo: logo,
  loading_icon: loading_icon,
  tracking: tracking,
  login_img: login_img,
  cart_img: cart_img,
  notification_img: notification_img,
  empty_list: empty_list,
  download_app: download_app,
  tin_tuc: tin_tuc,
  van_chuyen: van_chuyen,
  tracking_icon: tracking_icon,
  internet_icon: internet_icon,
  news_icon: news_icon,
  noimage: noimage,
};

export default Images;
