import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Verify from "src/views/AuthScreen/ChangePassword/ChangePassword";
import ConfirmForgotPassword from "src/views/AuthScreen/ConfirmForgotPassword/ConfirmForgotPassword";
import ConfirmRegister from "src/views/AuthScreen/ConfirmRegister/ConfirmRegister";
import ForgotPassword from "src/views/AuthScreen/ForgotPassword/ForgotPassword";
import Login from "src/views/AuthScreen/Login/Login";
import Register from "src/views/AuthScreen/Register/Register";
import Header from "src/components/Header/Header";
import CompanyImage from "src/components/CompanyImage/CompanyImage";
import ChatConsultation from "src/components/ChatConsultation/ChatConsultation";
import Footer from "src/components/Footer/Footer";
import { useEffect, useState } from "react";
import Chat from "src/components/Chat/Chat";
import useProfile from "src/hooks/useProfile";
import Home from "src/views/Home/Home";
import ProductDetail from "src/views/ProductDetail/ProductDetail";
import Checkouts from "src/views/Checkouts/Checkouts";
import Notification from 'src/views/Notification/Notification';
import OrderRequirement from "src/views/OrderRequirement/OrderRequirement";
import OrderRequirementDetail from "src/views/OrderRequirement/OrderRequirementDetail/OrderRequirementDetail";
import StaticPage from "src/views/StaticPage/StaticPage";
import News from "src/views/News/News";
import NewDetail from "src/views/News/NewDetail/NewDetail";
import Tracking from "src/views/Tracking/Tracking";
import ReceiverInfo from "src/views/ReceiverInfo/ReceiverInfo";
import ReceiverInfoDetail from "src/views/ReceiverInfo/ReceiverInfoDetail/ReceiverInfoDetail";
import FeaturedNews from "src/views/News/FeaturedNews/FeaturedNews";
import ProductCategory from "src/views/ProductCategory/ProductCategory";
import useQuery from "src/hooks/useQuery";
import { useMapReferral } from "src/api/referralApi";

const AppRouters: React.FC = () => {

  //State
  const [consultationAvailable, setConsultationAvailable] = useState(true);
  const location = useLocation();
  const [isAuth, setAuth] = useState(true);
  const [isNews, setIsNews] = useState(false);
  const profile = useProfile();
  const query = useQuery();
  const mapReferral = useMapReferral();
  

  useEffect(() => {
    let ref = query.get('ref');
    if(ref) {
      localStorage.setItem("REFERRAL_CODE", ref);
    } else {
      ref = localStorage.getItem("REFERRAL_CODE");
    }
    if(ref && profile) {
      mapReferral(ref)
        .then(res => console.log("Đã cập nhật người giới thiệu!"))
        .catch(err => console.log("Mã giới thiệu không đúng"))
    }
  }, [profile])



  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("auth-screen")) {
      setAuth(true);
      if (profile) navigate('/');
    } else {
      setAuth(false);
    }
    if (location.pathname.includes('/news')) {
      setIsNews(true);
    } else {
      setIsNews(false);
    }
  }, [location, navigate, profile])

  //Main
  return (
    <>
      {!isAuth && <Header />}

      <Routes>
        <Route path="/auth-screen/confirm-register" element={<ConfirmRegister />} />
        <Route path="/auth-screen/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth-screen/register" element={<Register />} />
        <Route path="/auth-screen/confirm-forgot-password" element={<ConfirmForgotPassword />} />
        <Route path="/auth-screen/change-password" element={<Verify />} />
        <Route path="/auth-screen" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/product-detail/:productId" element={<ProductDetail />} />
        <Route path="/product/category/:productCategoryId" element={<ProductCategory />} />
        <Route path="/checkout" element={<Checkouts />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/order-requirement" element={<OrderRequirement />} />
        <Route path="/order-requirement/:orderRequirementId" element={<OrderRequirementDetail />} />
        <Route path="/static-page/:key" element={<StaticPage />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewDetail />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/receiver-info" element={<ReceiverInfo />} />
        <Route path="/receiver-info/detail" element={<ReceiverInfoDetail />} />
      </Routes>

      {!isAuth && consultationAvailable ? <ChatConsultation /> : null}
      {!isAuth && <Chat setConsultationAvailable={setConsultationAvailable} />}
      {!isAuth && !isNews && <FeaturedNews />}
      {!isAuth && <CompanyImage />}
      {!isAuth && <Footer />}
    </>

  );
};

export default AppRouters;
