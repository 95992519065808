import './CommentComponent.css';
import React, { useState, useEffect, useCallback } from 'react';
import { CommentType } from 'src/api/models';
import { useGetCommentByCommentId, usePostNewComment } from 'src/api/backend-api';
import InputComponent from '../InputComponent/InputComponent';
import { useNavigate, useParams } from 'react-router';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';

interface IComment {
  comment: CommentType;
}
const CommentComponent: React.FC<IComment> = (props) => {
  //Value
  const params = useParams<{ newsId: string }>();
  const newsId: string = params.newsId;
  const profile = useProfile();
  const navigate = useNavigate();

  //State
  const [isLoadMore, setLoadMore] = useState(false);
  const [isRepsponse, setRepsponse] = useState(false);
  const [listComment, setListComment] = useState<CommentType[]>(null);
  const [commentValue, setCommentValue] = useState('');
  const [reloadFlag, setReloadFlag] = useState(false);

  //Function
  const getCommentByCommentId = useGetCommentByCommentId();
  const postComment = usePostNewComment();
  const addPopup = useAddPopup();

  const onRepsponse = useCallback(() => {
    if (profile) {
      setRepsponse(true);
      setLoadMore(true);
    } else {
      const url = `/login`;
      navigate(url);
    }
  }, [history, profile]);

  const onKeyDown = (keyCode: number) => {
    if (keyCode === 13) {
      const _comment: CommentType = {
        newId: Number(newsId),
        parentId: props.comment.commentId,
        commentValue: commentValue,
      };
      postComment(_comment)
        .then(() => {
          setReloadFlag(!reloadFlag);
          setCommentValue('');
          setRepsponse(false);
        })
        .catch(() => {
          addPopup({
            txn: {
              success: false,
              summary: 'Đã có lỗi xảy ra',
            },
          });
        });
    } else if (keyCode === 27) {
      setCommentValue('');
      setRepsponse(false);
    }
  };

  //Get comment
  useEffect(() => {
    if (isLoadMore) {
      getCommentByCommentId(props.comment.commentId)
        .then((data) => {
          setListComment(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getCommentByCommentId, isLoadMore, props.comment.commentId, reloadFlag]);

  return (
    <div className="comment-container p-0">
      <div>
        <div className="comment-avatar">
          <img src={props.comment.avataUrl} />
        </div>
        <div className="comment-value">
          <div> {props.comment.fullName} </div>
          <div>{props.comment.commentValue}</div>
        </div>
      </div>
      {isRepsponse && profile ? (
        <div className="comment-repsponse-value">
          <div className="add-comment-new-avatar">
            <img src={profile.info.avataUrl} />
          </div>
          <InputComponent
            isDisable={false}
            value={commentValue}
            width={'calc(100% - 60px)'}
            height={'fit-content'}
            cols={2}
            inputType={'text-area'}
            onChange={setCommentValue}
            placeholder={'Viết bình luận công khai'}
            onKeyDown={onKeyDown}
          />
        </div>
      ) : (
        <div
          className="comment-repsponse-btn"
          onClick={() => {
            onRepsponse();
          }}
        >
          Phản hồi
        </div>
      )}
      {isLoadMore ? (
        <div className="comment-repsponse-child">
          {listComment
            ? listComment.map((_comment) => {
                return <CommentComponent comment={_comment} />;
              })
            : null}
        </div>
      ) : null}
      {props.comment.commentLength > 0 && !isLoadMore ? (
        <div
          className="comment-repsponse-count"
          onClick={() => {
            setLoadMore(true);
          }}
        >
          <i className="fas fa-comment-dots"></i> Xem thêm {props.comment.commentLength} phản
          hồi
        </div>
      ) : null}
    </div>
  );
};

export default CommentComponent;
