import { useCallback } from 'react';
import { useFetch } from './backend-api';

export const useMapReferral = () => {
  const fetch = useFetch();
  return useCallback((referralCode: string) => {
    return fetch({
      url: `sccore/rest-api/referral/${referralCode}`,
      method: 'post',
    }) as Promise<boolean>;
  }, [fetch]);
};
