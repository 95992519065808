import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { MenuType } from './models';

export const useGetAllMenu = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/menu`,
      method: 'get',
    }) as Promise<MenuType[]>;
  }, [fetch]);
};

export const useGetMenuById = () => {
  const fetch = useFetch();
  return useCallback(
    (menuId: number) => {
      return fetch({
        url: `sccore/rest-api/menu/${menuId}`,
        method: 'get',
      }) as Promise<MenuType>;
    },
    [fetch],
  );
};

export const usePostMenu = () => {
  const fetch = useFetch();
  return useCallback(
    (menu: MenuType) => {
      return fetch({
        url: `sccore/rest-api/menu`,
        method: 'post',
        data: menu,
      }) as Promise<MenuType>;
    },
    [fetch],
  );
};

export const usePutMenu = () => {
  const fetch = useFetch();
  return useCallback(
    (menu: MenuType) => {
      return fetch({
        url: `sccore/rest-api/menu`,
        method: 'put',
        data: menu,
      }) as Promise<MenuType>;
    },
    [fetch],
  );
};

export const useDeleteMenu = () => {
  const fetch = useFetch();
  return useCallback(
    (menuId: number) => {
      return fetch({
        url: `sccore/rest-api/menu/${menuId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of Menu
