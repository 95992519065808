import './StaticPage.css';
import React from 'react';
import { useParams } from 'react-router';
import { useGetStaticPageByKey } from 'src/api/backend-api';
import { useEffect } from 'react';
import { useState } from 'react';
import { StaticPageType } from 'src/api/models';
import { useSetNotificationBadge } from 'src/state/application/hooks';
import { useGetNotification } from 'src/api/notificationApi';
import useProfile from 'src/hooks/useProfile';

const StaticPage: React.FC = () => {
  //Value
  const params = useParams<{ key: string }>();
  const key = params.key;
  const profile = useProfile();

  //State
  const [staticPage, setStaticPage] = useState<StaticPageType>(null);

  //Function
  const getStaticPageByKey = useGetStaticPageByKey();
  const getNotification = useGetNotification();
  const setNotificationBadge = useSetNotificationBadge();

  useEffect(() => {
    getStaticPageByKey(key)
      .then((_staticPage) => {
        setStaticPage(_staticPage);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getStaticPageByKey, key]);

  useEffect(() => {
    if (profile) {
      getNotification(1, 1)
        .then((data) => {
          setNotificationBadge(data.badge);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getNotification, profile, setNotificationBadge]);

  //Main
  return (
    <div className="body static-page-container col-lg-11 col-12 p-2">
      {staticPage ? <div> {staticPage.staticPageTitle} </div> : null}
      {staticPage ? (
        <div
          dangerouslySetInnerHTML={{
            __html: staticPage.staticPageValue,
          }}
        />
      ) : null}
    </div>
  );
};

export default StaticPage;
