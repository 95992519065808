import './FeaturedNews.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetNews, useGetStaticPageByKey } from 'src/api/backend-api';
import { NewsType, StaticPageType } from 'src/api/models';


const FeaturedNews: React.FC = () => {
  //Value
  const navigate = useNavigate();
  //State
  const [firstNew, setFirstdNew] = useState<NewsType>();
  const [news, setNews] = useState<NewsType[]>([]);

  const getNews = useGetNews();
  const getStaticPageByKey = useGetStaticPageByKey();
  const [featuredNewsAds, setFeaturedNewsAds] = useState<StaticPageType>(null);

  useEffect(() => {
    getStaticPageByKey('NEWS_EXTRA_INFO_GENERAL')
      .then((data) => {
        setFeaturedNewsAds(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getStaticPageByKey]);

  //End of function
  useEffect(() => {
    getNews(1, 7)
      .then((data) => {
        if (data.items.length > 0) {
          setFirstdNew(data.items[0]);
          setNews(data.items.slice(1));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getNews]);

  //Main
  return (
    <div className="featured-news-container">
      <div className="body-component-header mt-2">
        <div className="body-component-title more-new" onClick={() => {
          navigate(`/news`);
        }} title='Xem tất cả'>TIN TỨC</div>
      </div>
      <div className='inline home-news'>
        <div>
          {firstNew ? <div className='first-new-container' onClick={() => navigate(`/news/${firstNew.newId}`)}>
            <img className="first-new-avatar" src={firstNew.newAvatar} />
            <div className="first-new-content">
              <div className="first-news-item-title">{firstNew.newTitle}</div>
              <div className="first-news-item-des">{firstNew.description}</div>
            </div>
          </div> : null}

          <div className="featured-news-child">
            {news.map((item: NewsType, index: number) => {
              return (
                <div
                  key={`feature${index}`}
                  className="feature-new-item feature-new-resize"
                  onClick={() => navigate(`/news/${item.newId}`)}
                  title={item.newTitle}
                >
                  <img className="feature-new-avatar" src={item.newAvatar} />
                  <div className="featured-news-item-title">{item.newTitle}</div>
                </div>
              );
            })}
          </div>
        </div>
        {featuredNewsAds ? (
          <div
            className='hidden-small featured-news-adds'
            dangerouslySetInnerHTML={{
              __html: featuredNewsAds?.staticPageValue??""
            }}
          />
        ) : null}
      </div>
      

    </div>
  );
};

export default FeaturedNews;
