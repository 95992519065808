import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EnumOrderRequirementProgressStatus,
  EnumOrderRequirementProgressStatusTitle,
  OrderRequirementDetailsItemType,
  OrderRequirementType,
} from 'src/api/models';
import { useGetOrderRequirementById } from 'src/api/orderRequirementApi';
import OrderRequirementChildComponent from './OrderRequirementChildComponent/OrderRequirementChildComponent';
import './OrderRequirementComponent.css';

interface IOrderRequirementComponent {
  data: OrderRequirementType;
}

const OrderRequirementComponent: React.FC<IOrderRequirementComponent> = (props) => {
  //Value
  const value = props.data;
  const navigate = useNavigate();

  //State
  const [orderRequirementDetails, setOrderRequirementDetails] =
    useState<OrderRequirementDetailsItemType[]>(null);
  const [totalMoney, setTotalMoney] = useState(0);
  const [loadIndex, setLoadIndex] = useState(0);

  //Function
  const getOrderRequirementById = useGetOrderRequirementById();

  const toNumberFunction = (num: number) => {
    if (num !== null) {
      const string = num.toString();
      const length = string.length;
      let result = '';
      let textIndex = 0;

      if (num != 0) {
        if (string[0] != '-') {
          if (string.length > 3) {
            for (let index = length - 3; index >= 0; index -= 3) {
              result = string.substr(index, 3) + '.' + result;
              textIndex = index;
            }
            result = result.substr(0, result.length - 1);
            if (textIndex != 0) {
              result = string.substr(0, textIndex) + '.' + result;
            }

            return result + '¥';
          } else {
            return string + '¥';
          }
        } else {
          if (string.length > 4) {
            for (let index = length - 3; index >= 1; index -= 3) {
              result = string.substr(index, 3) + '.' + result;
              textIndex = index;
            }
            result = result.substr(0, result.length - 1);
            if (textIndex != 1) {
              result = string.substr(0, textIndex) + '.' + result;
            } else {
              result = '-' + result;
            }

            return result + '¥';
          } else {
            return string + '¥';
          }
        }
      } else {
        return 'Liên hệ';
      }
    }
  };

  const onCalcMoney = (money: number, index: number) => {
    setTotalMoney(totalMoney + money);
    setLoadIndex(index + 1);
  };

  useEffect(() => {
    getOrderRequirementById(value.orderRequirementId)
      .then((data) => {
        setOrderRequirementDetails(data.orderRequirementDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getOrderRequirementById, value.orderRequirementId]);

  //Main
  return (
    <div
      className={`order-requirement-wrapper`}
      onClick={() => {
        const url = `/order-requirement/${value.orderRequirementId}`;
        navigate(url);
        window.scrollTo(0, 0);
      }}
    >
      <div className="od-header">
        <div className="od-header-status">
          <div>{value.receiverFullname}</div>
          <div>
            {value.progressStatus == EnumOrderRequirementProgressStatus.Waiting ? (
              <div style={{ color: 'gray' }}>
                <i className="fas fa-spinner"></i>&ensp;
                {EnumOrderRequirementProgressStatusTitle.Waiting}
              </div>
            ) : value.progressStatus == EnumOrderRequirementProgressStatus.Sent ? (
              <div style={{ color: 'blue' }}>
                <i className="fas fa-shipping-fast"></i>&ensp;
                {EnumOrderRequirementProgressStatusTitle.Sent}
              </div>
            ) : value.progressStatus == EnumOrderRequirementProgressStatus.Error ? (
              <div style={{ color: 'red' }}>
                <i className="fas fa-exclamation"></i>&ensp;
                {EnumOrderRequirementProgressStatusTitle.Error}
              </div>
            ) : null}
          </div>
        </div>
        {orderRequirementDetails
          ? orderRequirementDetails.map((value, index) => {
              return (
                <OrderRequirementChildComponent
                  key={'OrderRequirementChildComponent' + value.orderRequirementDetailId}
                  index={index}
                  data={value}
                  loadIndex={loadIndex}
                  onCalcMoney={onCalcMoney}
                />
              );
            })
          : null}
      </div>
      <div className="od-bottom">
        Tổng số tiền:&ensp;
        <div className="od-prd-child-price">
          {toNumberFunction(totalMoney + props.data.shipFee)}
        </div>
      </div>
    </div>
  );
};

export default OrderRequirementComponent;
