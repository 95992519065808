import React, { useEffect, useRef, useState } from 'react';
import { CompanyInfoType } from 'src/api/models';
import './ChatConsultation.css';

import MessengerCustomerChat from 'react-messenger-customer-chat';
import { useGetCompanyInfo } from 'src/api/backend-api';
import { useGetProfileInfo, useNotifyChat } from 'src/state/application/hooks';
import { useGetConsulationRoom } from 'src/api/roomApi';
import { useNavigate } from 'react-router-dom';

const ChatConsultation: React.FC = () => {
  const navigate = useNavigate();
  const [pageId, setPageId] = useState(null);
  const [appId, setAppId] = useState(null);
  const [linkZalo, setLinkZalo] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const getCompanyInfo = useGetCompanyInfo();
  const notifyChat = useNotifyChat();
  const profile = useGetProfileInfo();
  const getConsulationRoom = useGetConsulationRoom();

  useEffect(() => {
    getCompanyInfo()
      .then((data) => {
        data.map((value: CompanyInfoType) => {
          if (value.companyInfoKey === 'CONTACT_ZALO') {
            setLinkZalo(value.href);
          } else if (value.companyInfoKey === 'CONTACT_MES_PAGE_ID') {
            setPageId(value.companyInfoValue);
          } else if (value.companyInfoKey === 'CONTACT_MES_APP_ID') {
            setAppId(value.companyInfoValue);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getCompanyInfo]);

  useEffect(() => {
    const handleClick = (event: any) => {
      const consulationChat = document.getElementById('consulation-chat');
      const jvsChat = document.getElementById('jvs-chat');
      const zaloChat = document.getElementById('zalo-chat');
      const facebookChat = document.getElementById('fb-root');
      if (consulationChat?.contains(event.target)) {
        setIsShow(!isShow);
      } else if (
        !jvsChat?.contains(event.target) &&
        !zaloChat?.contains(event.target) &&
        !facebookChat?.contains(event.target)
      ) {
        setIsShow(false);
      }
    };
    document.addEventListener('click', handleClick, false);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (!isShow) {
      const facebookChat = document.getElementById('fb-root');
      console.log(facebookChat);
      if (facebookChat) document.body.removeChild(facebookChat);
    }
  }, [isShow]);

  const openZalo = () => {
    window.open(linkZalo);
  };

  const openJvsChat = () => {
    if (profile?.accessToken) {
      console.log('chat');
      setIsShow(false);
      getConsulationRoom().then((res) => {
        notifyChat(res.roomId);
      });
    } else {
      navigate(`/auth-screen`);
    }
  };

  //Main
  return (
  //   <div id="consulation-chat" className="consulation-icon">
  //     <i className="fas fa-comment" id="btn-show-consolution"></i>
  //     <span className="consulation-tooltiptext">Liên hệ tư vấn</span>
  //   </div>
  // ) : (
    <div>
      <div className="jvs-chat-container" onClick={openJvsChat} id="jvs-chat">
        <img src="https://rs.jvscorp.jp/images/jvs-chat.png" />
      </div>
      {linkZalo ? (
        <a className="zalo-chat-container" onClick={openZalo} id="zalo-chat">
          <div></div>
        </a>
      ) : null}
      {/* {appId && pageId ? (
        <MessengerCustomerChat
          pageId={pageId}
          appId={appId}
          htmlRef={window.location.pathname}
        />
      ) : null} */}
    </div>
  );
};

export default ChatConsultation;
