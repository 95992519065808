import './Notification.css';
import React from 'react';
import useProfile from 'src/hooks/useProfile';
import NotificationScrollView from 'src/components/NotificationScrollView/NotificationScrollView';
import { Navigate } from 'react-router-dom';

const Notification: React.FC = () => {
  //Value
  const profile = useProfile();

  //Main
  return profile ? (
    <div className="body cart-container col-lg-11 col-12 p-0">
      <div className="cart-title">Thông báo</div>
      <NotificationScrollView />
    </div>
  ) : (
    <Navigate to="/auth-screen" />
  );
};

export default Notification;
