import React, { useState, useEffect } from 'react';
import { useGetProductById, useGetProductSerialById } from 'src/api/backend-api';
import { OrderRequirementDetailsItemType } from 'src/api/models';
import './OrderRequirementChildComponent.css';

interface IOrderRequirementChildComponent {
  data: OrderRequirementDetailsItemType;
  loadIndex: number;
  onCalcMoney: (...args: any[]) => any;
  index: number;
}

const OrderRequirementChildComponent: React.FC<IOrderRequirementChildComponent> = (props) => {
  //Value
  const data = props.data;

  //State
  const [thumbProductAvatar, setThumbProductAvatar] = useState(null);
  const [thumbSeraialAvatar, setThumbSerialAvatar] = useState(null);

  const [productName, setProductName] = useState(null);

  const [productPrice, setProductPrice] = useState(null);
  const [serialPrice, setSerialPrice] = useState(null);

  //Function
  const getProductById = useGetProductById();
  const getProductSerialById = useGetProductSerialById();
  const onCalcMoney = props.onCalcMoney;

  const toNumberFunction = (num: number) => {
    if (num !== null) {
      const string = num.toString();
      const length = string.length;
      let result = '';
      let textIndex = 0;

      if (num != 0) {
        if (string[0] != '-') {
          if (string.length > 3) {
            for (let index = length - 3; index >= 0; index -= 3) {
              result = string.substr(index, 3) + '.' + result;
              textIndex = index;
            }
            result = result.substr(0, result.length - 1);
            if (textIndex != 0) {
              result = string.substr(0, textIndex) + '.' + result;
            }

            return result + '¥';
          } else {
            return string + '¥';
          }
        } else {
          if (string.length > 4) {
            for (let index = length - 3; index >= 1; index -= 3) {
              result = string.substr(index, 3) + '.' + result;
              textIndex = index;
            }
            result = result.substr(0, result.length - 1);
            if (textIndex != 1) {
              result = string.substr(0, textIndex) + '.' + result;
            } else {
              result = '-' + result;
            }

            return result + '¥';
          } else {
            return string + '¥';
          }
        }
      } else {
        return 'Liên hệ';
      }
    }
  };

  useEffect(() => {
    if (data.productSerialId) {
      getProductSerialById(data.productSerialId)
        .then((r) => {
          getProductById(r.productId)
            .then((_product) => {
              setThumbProductAvatar(_product.thumbAvatar);
              setProductName(_product.productName);
              setProductPrice(_product.price);
            })
            .catch((e) => {
              console.log(e);
            });

          setSerialPrice(r.price);
          setThumbSerialAvatar(r.thumbAvatar);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getProductById(data.productId)
        .then((r) => {
          setThumbProductAvatar(r.thumbAvatar);
          setProductName(r.productName);
          setProductPrice(r.price);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [data.productId, data.productSerialId, getProductById, getProductSerialById]);

  useEffect(() => {
    if (serialPrice && props.index == props.loadIndex) {
      onCalcMoney(serialPrice, props.index);
    }
  }, [onCalcMoney, serialPrice, props.index, props.loadIndex]);

  //Main
  return (
    <div className="od-prd-child-container">
      <div className="od-prd-child_avatar">
        <img src={thumbSeraialAvatar ? thumbSeraialAvatar : thumbProductAvatar} alt="avatar" />
      </div>
      <div className="od-prd-child_des">
        <div className="od-prd-child-name">{productName}</div>
        <div className="od-prd-child-name">SL: {props.data.quantity}</div>
        <div className="od-prd-child-price">
          {serialPrice ? toNumberFunction(serialPrice) : toNumberFunction(productPrice)}
        </div>
      </div>
    </div>
  );
};

export default OrderRequirementChildComponent;
